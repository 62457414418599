import { Component, HostListener } from "@angular/core"

const allowedPaths = ["guest-sched"]

@Component({
	selector: "app-browser-tab-error",
	template: `
		@if (isError) {
			<div class="browser-tab-error">המערכת פתוחה בטאב אחר</div>
		}
	`,
})
export class BrowserTabErrorComponent {
	isError: boolean = false
	ngOnInit() {
		localStorage.setItem("openpages", Date.now().toFixed())
	}
	@HostListener("window:storage", ["$event"])
	handleStorage(e: StorageEvent) {
		if (e.key == "openpages") {
			// Emit that you're already available.
			localStorage.setItem("page_available", Date.now().toFixed())
		}
		if (e.key == "page_available") {
			const isPathAllowed = allowedPaths.some((url) =>
				location.pathname.includes(url)
			)
			this.isError = !isPathAllowed
		}
	}
}
