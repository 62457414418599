import { Component, OnInit } from "@angular/core"
import { CcParamsGen } from "@app/cc-params-gen/cc-params-gen"

@Component({
	selector: "app-cc-params-user-details-website",
	templateUrl: "./cc-params-user-details-website.component.html",
})
export class CcParamsUserDetailsWebsiteComponent extends CcParamsGen {
	saveObjName: string = "user" //which part to save while updating
	imageErrors: any = {}
	image: any = ""
	imageUpl: boolean = false
	logoUpl: boolean = false

	clsDefaults: any = { xs: 12, md: 2 }
	tabIndexStart: number = 55
	rows: any[] = [
		[
			{ name: "name_web", lang: "name" },
			{ name: "title_web", lang: "user_title" },
		],
		[
			{ name: "address_web", lang: "address", xs: 6 },
			{ name: "phone_web", lang: "phone", xs: 6 },
			{ name: "email_web", lang: "email", xs: 6 },
		],
		[
			{ name: "languages", md: 8 },
			{ name: "expertise", lang: "areas_of_expertise", md: 8 },
			{ name: "website", lang: "website_link", md: 8 },
		],
	]

	async ngOnInit() {
		let curTabIndex = this.tabIndexStart
		this.rows.forEach((rowFields) => {
			rowFields.forEach((field) => {
				field.tabIndex = curTabIndex
				curTabIndex++
			})
		})
	}

	validateEntireForm() {
		//validate all fields and detrmine if can be submitted (or has errors)
		let fieldsValidationObjects: any = {
			//default - is new contact
			email_web: { email: null, not_empty: true },
		}

		this.errors = {}
		this.isFormSubmittable = this.validator.validateEntireForm(
			this.configcliniq,
			this.errors,
			fieldsValidationObjects
		)
	}

	async uploadImage(files: FileList = null, is_logo: boolean) {
		this.image = files ? files.item(0) : ""
		let file = this.image || undefined
		const dlField = is_logo ? "logoUpl" : "imageUpl"
		this[dlField] = true
		const res = await this.apiService.upload("upload_user_logo", {
			is_logo: is_logo ? 1 : 0,
			logo_file: file,
		})
		this[dlField] = false
	}
}
