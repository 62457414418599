import { StoreService } from "@app/services/store.service"
import { Store } from "@app/definitions/types"
import {
	Component,
	HostListener,
	NgZone,
	computed,
	signal,
} from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { ApiService } from "@app/services/api.service"
import { ModalService } from "@app/services/modal.service"
import { fromEvent, Subject } from "rxjs"
import { throttle, throttleTime } from "rxjs/operators"
import { EventsService } from "./services/events.service"
import { LangService } from "./services/lang.service"
import { DataLayerService } from "./services/data-layer.service" // gets hold of the data layer service we just created
import { ErrorService } from "./services/error-service.service"

declare let gtag: Function

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
})
export class AppComponent {
	hasApprovedCookies: boolean = false //flag for whether user approved cookies
	motionAndClickSubject = new Subject() //dddddddGFHdfdfdf%^&Y^URHTGHgdfgfdgfdgfd#$^%Y&THJ
	hasHelp: boolean = true
	networkOffline: boolean = false

	constructor(
		public apiService: ApiService,
		public lang: LangService,
		public modalService: ModalService,
		private eventsService: EventsService,
		protected zone: NgZone,
		public _router: Router,
		public store: StoreService,
		private _dataLayerService: DataLayerService,
		private errorService: ErrorService
	) {
		apiService.verifyCsrf() //app startup - request new csrf

		this.errorService.networkOfflineSubj.subscribe(
			(offline: boolean) => (this.networkOffline = offline)
		)

		if ("caches" in window) {
			caches.keys().then(function (keyList) {
				return Promise.all(
					keyList.map(function (key) {
						return caches.delete(key)
					})
				)
			})
		}
		if (window.navigator && navigator.serviceWorker) {
			navigator.serviceWorker.getRegistrations().then(function (registrations) {
				for (let registration of registrations) {
					registration.unregister()
				}
			})
		}

		this._router.events.subscribe((event) => {
			// subscribe to router events
			if (event instanceof NavigationEnd) {
				//if our event is of our interest
				this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
			}
		})

		this.hasHelp =
			!window.location.pathname.includes("guest-sched") &&
			!window.location.pathname.includes("guest-payment")
		const ita_success = window.location.search.split("ita_success=")[1]
		if (ita_success !== undefined) {
			setTimeout(() => {
				this.modalService.openToast(
					this.lang.getVal(
						Number(ita_success) ? "token_received" : "token_was_not_received"
					)
				)
			}, 2000)
		}
	}
	// getMainVers(){
	//   const el:any=Array.from(document.getElementsByTagName("SCRIPT")).find((it:any)=>it?.src && it.src.includes("/main."));
	//   if(el?.src){
	//     const arr=el.src.split(".");
	//     if(arr.length>2){
	//       arr.pop();
	//       const code=arr.pop();
	//     }
	//   }
	// }

	langChanged() {
		//new lang chosen by select - call the method in the lang service
		this.lang.setLang("", true)
	}

	@HostListener("document:click") //on document click - inform the event service (used for closing popus and modals etc)
	docClick() {
		this.eventsService.onDocClick()
	}

	@HostListener("window:resize", ["$event"]) //on resize - inform the event service (used for isMobile detection)
	onResize(event) {
		this.eventsService.checkShouldMobileChange(event.target.innerWidth)
	}

	@HostListener("document:keydown.escape", ["$event"]) //on ESC key (used to close modals, return to default view, close autocomplete suggestion list)
	onKeyDownEscape() {
		this.eventsService.escapeKeySubj.next(true) //inform the event service of REGULAR ESC event
		if (!this.modalService.closeLatestModal()) {
			//try to close the latest (most recently opened) modal
			this.eventsService.escapeKeyNonModalSubj.next(true) //if no modal was open, inform the event service of NON MODAL ESC event (used to return to default state in patient and similar components)
		}
	}

	openHelp() {
		//open the error modal (in help mode, not error mode)
		this.modalService.openMulti("error")
	}

	async ngOnInit() {
		if (!navigator.cookieEnabled) {
			alert("Please enable cookies to use this website")
		}

		if (sessionStorage.getItem("isPatientLoggedIn")) {
			this.apiService.postLoginPatient()
			this.apiService.restartLogoutTimeout()
		}

		this.motionAndClickSubject.pipe(throttleTime(500)).subscribe(() => {
			this.apiService.restartLogoutTimeout()
		})

		this.zone.runOutsideAngular(() => {
			//register mousemove and click for updating the logout interval (postponing auto-logout)
			// window.document.addEventListener('mousemove', () => this.apiService.restartLogoutTimeout());
			// window.document.addEventListener('click', () => this.apiService.restartLogoutTimeout());
			window.document.addEventListener("mousemove", () =>
				this.motionAndClickSubject.next(true)
			)
			window.document.addEventListener("click", () =>
				this.motionAndClickSubject.next(true)
			)
		})
		;(this.hasApprovedCookies as any) =
			document.cookie &&
			document.cookie
				.split(";")
				.map((it) => it.trim().split("="))
				.filter((it) => it[0].indexOf("approveCookies") == 0).length
	}

	approveCookies() {
		this.hasApprovedCookies = true
		document.cookie = "approveCookies=1"
	}

	@HostListener("window:beforeinstallprompt", ["$event"]) //on document click - inform the event service (used for closing popus and modals etc)
	beforeinstallprompt(event) {
		event.preventDefault()
		this.store.deferredPrompt = event
		console.log(`'beforeinstallprompt' event was fired.`)
	}
	@HostListener("window:appinstalled", ["$event"]) //on document click - inform the event service (used for closing popus and modals etc)
	appinstalled(event) {
		event.preventDefault()
		this.store.deferredPrompt = null
		console.log("PWA was installed")
	}
}
