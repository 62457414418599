<div>
	<div
		style="padding: 10px"
		class="row"
	>
		<div class="col-8">
			<table>
				<tbody>
					<tr>
						<td colspan="2">
							<h3 style="font-weight: bold">
								{{ dataObj?.title }} {{ dataObj?.name }}
							</h3>
						</td>
					</tr>
					@if (dataObj?.occupation) {
						<tr class="details-list">
							<td icon="profession"></td>
							<td>{{ dataObj?.occupation }}</td>
						</tr>
					}
					@if (dataObj?.phone) {
						<tr class="details-list">
							<td icon="phone"></td>
							<td>{{ dataObj?.phone }}</td>
						</tr>
					}
					@if (dataObj?.email) {
						<tr class="details-list">
							<td icon="email"></td>
							<td>{{ dataObj?.email }}</td>
						</tr>
					}
					@if (dataObj?.website) {
						<tr class="details-list">
							<td icon="link-grey"></td>
							<td>
								<a
									[href]="dataObj?.website"
									target="_blank"
									>{{ dataObj?.website }}</a
								>
							</td>
						</tr>
					}
				</tbody>
			</table>
		</div>
		@if (dataObj?.image) {
			<div
				class="col-4"
				style="padding: 10px"
			>
				<!--  if there is no image of the user -->
				<img
					[src]="
						environment.serverUrl +
						(dataObj?.image
							? '/user-image?fdsewrfsdfds=' +
								fdsewrfsdfds +
								'&hgfe5ty6hjtgft454tr=' +
								hgfe5ty6hjtgft454tr
							: '/assets/img/missing_avatar.gif')
					"
					[alt]="dataObj?.name + ' ' + dataObj?.title"
					style="
						width: 80px;
						height: 80px;
						border-radius: 50%;
						margin-top: 30px;
					"
				/>
			</div>
		}
		@if (dynamicArea == "lines") {
			<div class="row">
				<div class="text-end">
					<button class="btn btn-primary home-button">
						<a
							[href]="'https://wa.me/' + dataObj?.phone"
							style="color: white"
							target="_blank"
							postIcon="leftArrow"
							>{{ "whatsapp" | langVal }}
						</a>
						<!--{{"to1" | langVal}}{{dataObj?.name}}-->
					</button>
				</div>
			</div>
			@if (eventsService.isMobile) {
				<div class="row">
					<div class="text-end">
						<button
							class="btn btn-primary home-button"
							(click)="setMeetingClick()"
							postIcon="leftArrow"
						>
							{{ "to_meeting_request" | langVal }}
							<!--{{dataObj?.name}}-->
						</button>
					</div>
				</div>
				<div class="row">
					<div class="text-end">
						<button
							class="btn btn-primary home-button"
							(click)="goToForm()"
							postIcon="leftArrow"
						>
							{{ "to_meeting-request_no_calendar_with" | langVal }}
							<!--{{dataObj?.name}}-->
						</button>
					</div>
				</div>
			}
		}

		<div [hidden]="dynamicArea != 'lines' || !dataObj?.userdetail_lines">
			@if (dataObj?.expertise) {
				<div class="details-text">
					<h4>{{ "expertise" | langVal }}</h4>
					<p>{{ dataObj?.expertise }}</p>
				</div>
			}
			@if (dataObj?.languages) {
				<div class="details-text">
					<h4>{{ "languages" | langVal }}</h4>
					<p>{{ dataObj?.languages }}</p>
				</div>
			}
			@if (dataObj?.address) {
				<div class="details-text">
					<H4>{{ "address" | langVal }}</H4>
					<p>{{ dataObj?.address }}</p>
				</div>
			}
			@for (
				userdetail_line of dataObj?.userdetail_lines;
				track userdetail_line
			) {
				<div class="details-text">
					<h4>{{ userdetail_line.subject_web }}</h4>
					<p [innerHTML]="userdetail_line.description_web | safeHtml"></p>
				</div>
			}
		</div>
	</div>
	<div [hidden]="dynamicArea != 'hours'">
		<app-guest-hours-sched
			#hoursComp
			(chosenHourOut)="backFromHours($event); scrollToTop()"
			(backEv)="dynamicArea = 'lines'; scrollToTop()"
			[dataObj]="dataObj"
		></app-guest-hours-sched>
	</div>
	<div [hidden]="dynamicArea != 'form'">
		<app-guest-add-meeting-form
			#formComp
			[saveObj]="saveObj"
			[dataObj]="dataObj"
			(backEv)="backFromForm($event); scrollToTop()"
		></app-guest-add-meeting-form>
	</div>
</div>
