export const lang = {
	"1month": "Every month",
	"2month": "Every 2 months",
	"2weekly": "Every 2 weeks",
	"3weekly": "Every 3 weeks",
	"???": "???",
	Journalentry: "Journal entry",
	"M/P": "M/P",
	Prolonged: "Prolonged",
	"R/O": "R/O",
	REC: "REC",
	"S/P": "S/P",
	Suppliers: "Suppliers",
	"Susp.": "Susp.",
	VAT_number: "VAT number",
	a_meeting: "there is a meeting - ",
	a_meeting_note: "Note about a meeting",
	a_meeting_on: "a meeting on",
	a_task_note: "A Task note",
	a_password_reminder_confirm:
		"Confirming the request will enable your patient to reset the password.",
	a_password_reminder_link: "Please enter this link to view the details",
	a_password_reminder_request_from_patient:
		"A request to reset your patient password ha been recieved. ",
	a_patient_of_yours_wants_to_reset_password:
		"A patient of yours wants to reset password",
	a_reminder: "A reminder",
	a_reminder_with_the_exceptions: "A reminder with the exceptions",
	about: "about",
	about_the_writer: "About the writer",
	about_us: "About Us",
	accept_events: "Accept Events",
	account: "Account",
	account_activation_error: "Account activation error",
	account_in_hashav: "account in accountant software",
	account_in_hashav_cash: "Cash account",
	account_in_hashav_cheque: "Cheque account",
	account_in_hashav_credit: "Credit account",
	account_in_hashav_patient_all: "All patients",
	account_in_hashav_vat: "Vat",
	account_in_hashav_income: "Income",
	account_in_hashav_expenses: "Expenses",
	account_num: "Account num",
	account_type: "Account type",
	accountant_insert: "Insert accountant parameters",
	accountant: "Accountant parameters",
	accountant_email: "Accountant email",
	accountant_every_copy: "Send every document",
	accounting: "Accounting",
	accounting_explanation: "Accounting explanation",
	action: "Action",
	active: "Active",
	active_program: "Active program",
	active_your_account_now: "activate your account.",
	add: "Add",
	add_contactway: "Add a phone email or address",
	add_location: "Add location",
	add_tarif: "add tarif",
	add_new: "Add new ",
	add_new_contact: "new contact",
	add_new_patient: "new  patient",
	add_new_user: "Add new user",
	add_one_more_invoiceline: "Add one more line to invoice",
	add_patient: "Insert patient",
	add_payor: "Add payor",
	add_patient_extrs: "Detailed patient insert",
	addd: "Add ",
	added_at: "added at",
	address: "Address",
	address_line_1: "Address line 1",
	address_line_2: "Address line 2",
	admin: "Admin",
	admin_action: "Admin action",
	adminconfigs: "Adminconfigs",
	admininvoice_details: "Admin invoice details",
	admininvoicelines: "Admin invoice lines",
	admininvoices: "Admin invoices",
	administration: "Administration",
	advanced_by_percent:
		"Advance according to the percentage of the annual turnover",
	advanced_percent: "Advance percentage",
	affect: "Affect",
	affect_desc: "אאוטימי, מלא, יציב, תואם לתכנים",
	age: "Age",
	all_arrived: "All Arrived",
	all_invoices: " invoices",
	all_options: "All options",
	ownerbankaccounts: "bankaccounts",
	all_patients: " patients",
	all_patients_all_fus: "Advanced calendar",
	all_users: " users",
	allday: "day",
	alone: "Alone",
	already_exists: "already exists",
	patient_already_exists: "Patient already exists ",
	already_registered: "Already registered",
	amount: "Amount",
	amountXfrequency: "Amount X Frequency",
	amount_output: "Amount per time",
	amountt: "Amount",
	an_error_occurd: "an Error occurred!",
	an_error_occured: "an error occured",
	announce: "Update",
	announces: "Updates",
	appearance: "Appearance",
	appointments: "Appointments",
	approve: "Approval",
	arrived: "Arrived",
	at_least_chars: "At least chars",
	atache: "Atache",
	atc5_code: "ATC5_code",
	atc5_name: "ATC5 name",
	attach: "Attach",
	attachment: "Attachment",
	auto_message: "Self reminder",
	auto_renew: "Auto renew",
	auto_renew_billing: "Auto renew billing",
	autotexts: "Texts",
	autotext: "Text",
	back: "back",
	back_home: "Back to home page",
	back_to_dates: "Back to dates range",
	back_to_journal: "Back to Daily report",
	back_to_patient_details: "Back to patient details",
	back_to_details: "Back to details",
	back_to_medics: "Back to medication list",
	background: "Background",
	"bad-credentials": "Bad credentials",
	bad_credentials: "bad credentials",
	bad_email: "Wrong email",
	balance: "Balance",
	bank: "Bank",
	bank_code: "Bank code",
	bank_id: "Bank",
	bank_name: "Bank name",
	bank_transfer: "Transfer",
	bankaccount: "Bank account",
	bankaccount_number: "Bankaccount number",
	bankaccounts: "Bankaccounts",
	banks: "Banks",
	barter: "Barter",
	base_tables: "Base tables",
	basic_account_info: "Basic account info",
	before_today: "a date before today is forbidden",
	belong_to_group: "Belong to group",
	beshaa: "Time",
	betaarich: "Date",
	blog: "Blog",
	body: "Body",
	branch: "Branch",
	branch_id: "Branch",
	branch_name: "Branch name",
	branches: "Branches",
	break: "Break and total",
	break_length: "Break length",
	buy: "Buy",
	buy_again: "Buy Again",
	buy_selected_items: "Buy Selected Items",
	by: "by",
	by_attending: "By attending",
	by_clicking_i_agree: "I agree",
	"by_downloading_this email":
		"* By downloading this invoice, you herby approve accepting invoices by email.",
	by_group: "By group",
	by_month: "By month",
	by_patient: "By patient",
	by_payor: "By payor",
	by_type: "By type",
	by_year: "By year",
	calc_income_as_next_month:
		"Calculate invoices from next month in last month debt",
	calendar: "Calendar",
	calendar_config: "Calendar config",
	calendar_end_time: "End time on calendar",
	calendar_rows_interval: "Calendar rows interval",
	calendar_start_time: "Start time on calendar",
	cancel: "Cancel",
	canceled: "Canceled",
	canceled_meeting: "Canceled meeting",
	cant_delete_fundamental_followup_types:
		"Cant delete fundamental followup types",
	cant_edit_past: "Cant edit past",
	update_before_yesterday: "It is forbidden to insert/update past events",
	vacation_before_tomorrow:
		"It is not possible to enter a vacation today or in the past",
	vacations_holiday_create:
		"Create/update vacations based on the holidays you chose",
	vacations_holiday_create_remarks:
		"Each vacation can be changed/deleted through the calendar",
	vacations_holiday_create_remarks_extra:
		"* repressing this button will regenerate the vacation and will rewrite any manual changes",
	cant_find_user_data: "Cant find user data",
	cash: "Cash",
	certified_copy: "Certified Copy",
	change: "Change",
	change_existing_default: "Change existing default",
	change_limit_date: "Change meetings end date",
	change_password: "Change Password",
	change_plan: "Change plan",
	change_program: "Change program",
	change_series: "Change meetings/series of meetings",
	characters: "characters",
	charge: "Charge",
	charge_mode: "Charge mode",
	charged: "Charged",
	charged_manualy: "Charged manualy",
	charged_manualy_payor: "Charged manualy-payor",
	chat: "Chat",
	chat_link: "Enter chat",
	chatpatients: "Patients in chat",
	check_list: "Check list and print",
	"checking_the_verification_code.": "Checking the verification code.",
	checkout: "Checkout",
	checkout_with_paypal: "Checkout with paypal",
	cheque: "Cheque",
	cheque_deposit: "cheque deposit",
	cheque_num: "Cheque",
	cheque_report: "deposits",
	cheques: "Cheques",
	cheques_report: "deposits",
	choose_file: "Choose file",
	choose_option: "choose_option",
	choose_template_design: "Choose template design",
	chosen_items: "Chosen items",
	city: "City",
	clear: "Clear",
	click_here: "click here",
	click_here_to_update_card: "Click here to update your card details: ",
	click_here_to_view_message: "Please click on this link to view your message",
	click_link_to_register:
		"Please click on this link to register to the messaging system",
	click_me: "Click me",
	clinical: "Clinical",
	clinic_financial: "Clinical & financial",
	cliniq: "Cliniq",
	cliniq_management: "Cliniq management",
	close: "Done",
	close_btn: "Close",
	closed: "Closed",
	show_closed: "All tasks",
	code: "Code",
	code_is_not_valid: "Code is not valid",
	codecolor: "Codecolor",
	coin: "shekels",
	coin_symbol: "$",
	color: "color",
	comment: "Comment",
	comment_successfully_added: "Comment successfully added",
	comments: "Comments",
	configcliniqs: "System settings",
	configcliniqs_accountant: "Email to accountant paramters",
	configuration: "Settings",
	confirm: "Confirm",
	confirm_all_events_that_not_conflict: "Confirm all events that not conflict",
	confirm_change_default: "Confirm change default",
	confirm_change_default_invoice_per_followup:
		"You are about to change the invoices system, please confirm or dsimiss",
	confirm_duplicate:
		"The name already exists, Are you sure you want to duplicate?",
	confirm_event_series: "Confirm event series",
	confirm_meeting_on_holiday: "Confirm a meeting on holiday",
	confirm_password: "Confirm password",
	confirmation: "Confirmation",
	confirmation_failed:
		"The registration failed, please contact us by clicking on the ? and we shall assist.",
	confirmation_of_your_order: "Confirmation of your order",
	conflict_error: "Conflict error",
	conflict_in_the_future: "Conflict in the future",
	conflict_vent_in_the_future: "Conflict vent in the future",
	conscious: "conscious",
	consciousness: "Consciousness",
	consciousness_desc: "צלול",
	contac_us_form: "Contact us form",
	contac_us: "Contact us",
	contact: "Contact",
	contact_details: "Contact in details",
	contact_me: "Contact me",
	contact_name: "contact_name",
	contact_page: "Contact page",
	contact_us: "Contact Us",
	contacts: "contacts",
	contacttype: "Contact type",
	contacttype_id: "Contact type",
	contacttypes: "Contact types",
	contactway: "Contact details",
	contactway_1: "Contact details",
	contactway_identifier: "Contactway identifier",
	contactways: "Contact details",
	content: "Content",
	thinking_content: "Thinking Content",
	continent: "Continent",
	continue: "Continue",
	continue_to_email: "Continue to email",
	control_panel: "Control Panel",
	cooperation: "cooperation",
	country: "Country",
	courier: "Courier",
	couriers: "Couriers",
	create: "Create",
	create_a_translated_certified_copy: "Create a translated certified copy ",
	create_contact: "Create contact",
	create_excel: "Create excel",
	create_followup: "Create followup",
	create_patient: "Create patient",
	create_test: "Create a Questionnaire",
	create_user: "Create user",
	created: "Created",
	created_at: "Created at",
	created_at_render: "Created at",
	credit: "Credit",
	credit_card: "Credit",
	credit_card_4: "4 last digits",
	credit_card_4_digits: "4 last digits of credit cars",
	credit_card_company: "Credit card company",
	credit_card_expired: "Your credit card has expired",
	credit_card_expired_admin:
		"'s credit card has expired, an email has been sent to the user",
	credit_card_expired_user:
		"The credit card information you have on file with us has expired. Please update your billing information with a valid payment method as soon as possible.",
	credit_invoice: "Credit invoice",
	credit_invoice_short: "Credit",
	credit_invoice_against: "Credit invoice against ",
	creditcard: "Credit card",
	creditcards: "Credit cards",
	creditcomp: "Credit card company",
	creditcomps: "Credit card companies",
	credittype: "Credit type",
	credittypes: "Credit types",
	curent: "Curent",
	cycle: 'המחזור העיסקי בש"ח',
	daily: "Daily",
	data: "Data",
	data_saved_on: "The data was saved on",
	date: "Date",
	do_date: "Date of execution",
	date_range: "Choice",
	date_start_accounting: "Date to start accounting",
	date_to: "Untill date",
	day: "Day",
	day_before: "a day_before",
	day_end: "Day end",
	day_start: "Day start",
	days: "Days",
	dear: "Dear",
	debt: "Debt",
	calculated_debt: "Calculated debt",
	debts: "Debts",
	real_debt: "True debt",
	debt_details: "Meeting list and debts",
	debt_patient: "Patient debt",
	debt_report: "Debt",
	debt_total: "Total debt",
	debts_report: "Debt",
	debtUntilDate: "Debt until",
	debtUntilNow: "Debt now",
	default: "Default",
	default_font_size: "Default font size(for textuals fields)",
	font_size: "font size",
	default_heading: "Default heading",
	default_price: "Default price",
	default_signature: "Default signature",
	defaultpatienttype: "Default patient type",
	delete: "Delete",
	"delete-": "deleting ",
	delete_failed: "Delete failed",
	delete_successful: "Deleted!",
	deleted: "deleted",
	is_deleted: "Deleted",
	deleted_remarks: "Deleted remarks",
	deleted_at: "deleted at",
	delusions: "Delusions",
	delusions_desc: "Not found",
	demo_user:
		"Please note that you are on a demo version and your data is not private and not exclusive! ",
	departure: "Departure",
	deposit_to: "Deposit to",
	deposits: "Deposits",
	description: "Description",
	description_and_instractions: "Description and instractions",
	thank_you_for_filling: "The questionnaire was accepted, thank you.",
	test_by: "A questionnaire from ",
	destination: "Destination",
	details: "Details",
	diagnosis: "Diagnosis",
	diagp: "Diagnosis",
	diagp_name: "Diagp name",
	diagpine: "Diagpine",
	diagppatient: "Diagppatient",
	diagppatients: "Diagppatients",
	diagps: "Diagnosis",
	discount: "Discount",
	divorced: "Divorced",
	do_nothing: "Do nothing",
	do_you_confirm: "Confirm",
	do_you_confirm_delete: "Do you confirm delete?",
	do_you_confirm_change: "Do you confirm change?",
	doc_type: "Document type",
	doc_type_csv: "CSV",
	doc_type_hash_hesh: "Hashavshevet-customers",
	doc_type_hash_mov: "Hashavshevet-movements",
	doc_type_hash_xlsx: "Hashavshevet-movements xlsx",
	doc_type_pdf: "PDF",
	fin_doctype: "Financial document",
	doctype: "document",
	finResource_list: "Financial documents",
	doctor_diary: "Diary",
	document: "Document",
	document_code: "Document code",
	document_copy: "Document copy",
	document_preview: "Document preview",
	document_template: "Document template",
	document_templates: "Document templates",
	document_type: "Document type",
	documentation: "Documentation",
	documents: "Documents",
	documenttemplates: "Document templates",
	documenttypes: "Documenttypes",
	does_not_take_time: "Does not take time",
	done: "Done",
	done_and_end: "Done and ended",
	dosage: "Dosage",
	dosage_form: "Dosage form",
	download: "download",
	move_to_download: "To download",
	download_app: "Application",
	download_pdf_after_save: "Download pdf after save",
	save_process: "The document is saved",
	download_process: "The document is downloaded",
	download_success: "Download in process",
	duplicate: "Duplicate",
	"duplicate-": "duplicating ",
	duration: "Duration",
	dx_status: "Dx Status",
	early_adopter: "Do you consider yourself an early_adopter?",
	edit: "Edit",
	edit_admininvoice: "Edit admin invoice",
	edit_admininvoiceline: "Edit admin invoice line",
	edit_autotext: "Edit text",
	edit_bank: "Edit bank",
	edit_bankaccount: "Edit bankaccount",
	edit_branch: "Edit branch",
	edit_configcliniq: "Edit system cofiguration",
	edit_configcliniq_first: "Edit system cofiguration for the first time",
	edit_contact: "Edit contact",
	edit_contacttype: "Edit contacttype",
	edit_contactway: "Edit contactway",
	edit_courier: "edit_courier",
	edit_creditcard: "Edit Credit card",
	edit_creditcomp: "Edit Credit card company",
	edit_credittype: "Edit Credit type",
	edit_diagp: "Edit diagp",
	edit_document: "Edit document",
	edit_documenttype: "Edit documenttype",
	edit_email: "Edit email",
	edit_expense: "Edit expense",
	edit_expensegroup: "Edit expensegroup",
	edit_expensetype: "Edit expense type",
	edit_followup: "Edit followup",
	edit_followuptype: "Edit followup type",
	edit_header_and_settings: "Edit header and user settings",
	edit_headture: "Edit header & signature",
	edit_invoice_num: "First invoice number",
	edit_num: "Enter first number",
	edit_labtest: "Edit labtest",
	edit_medic: "Edit medic",
	edit_meetingtype: "Edit meeting type",
	add_meetingtype: "Add meeting type",
	add_expensetype: "Add expense type",
	edit_memo: "Edit memo",
	edit_nondrug: "Edit nondrug",
	edit_notification: "Edit notification",
	edit_patient: "Edit patient",
	edit_patienttype: "Edit patienttype",
	edit_phone: "Edit phone",
	edit_profile: "Edit Profile",
	edit_proforma: "Edit proforma",
	edit_proforma_num: "First proforma number",
	edit_justinvoice_num: "First invoice number",
	edit_receipt_num: "First receipt number",
	edit_proformaline: "edit_proformaline",
	edit_program: "Edit program",
	edit_purchase: "Edit Purchase",
	edit_status: "Edit status",
	edit_supplier: "Edit supplier",
	edit_tax: "Edit tax",
	edit_test: "Edit Questionnaire",
	edit_user: "Edit User",
	editor: "Editor",
	email: "E-mail",
	email_not_must: "E-mail(not required)",
	email_accountant: "Email monthly documents to accountant",
	email_choose_or_insert_new: "Choose or insert new email",
	email_copy: "Email copy",
	email_phone: "Email or phone",
	email_not_found: "This email does not exist",
	email_support:
		'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to="mycliniq.manage@gmail.com" target="_blank"',
	email_support_mobile:
		'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to="mycliniq.manage@gmail.com" target="_blank"',
	email_suspended: "My-Cliniq - suspension notice",
	email_taken: "Email already exists",
	email_tracking: "Email tracking",
	emails: "E-mails",
	emails_phones: "E-mails/phones",
	empty: "Empty",
	en: "English",
	end: "End",
	end_active_chat: "End active chat",
	end_date: "End date",
	end_change_date: "End/change date",
	end_date_must_be_bigger_than: "End date must be bigger than start date",
	ended: "Ended",
	ended_successfully: "ביצוע ממשק פתוח הסתיים בהצלחה",
	ends_at: "Ends at",
	eng: "English",
	enter_your_new_password: "Enter your new password",
	error: "error",
	"error_creating_your_account.": "Error creating your account.",
	error_saving_template: "Error saving template",
	errors_report: "Report Errors and bugs or file requests",
	event_end: "Event end",
	every: "every",
	exactly: "Exactly",
	existing: "Existing",
	exp_modal_choose_date: "select date",
	exp_modal_choose_report_by_date: "Report by patient",
	exp_modal_title: "Accountant report",
	expand: "Expand",
	expense: "Expense",
	expense_description: "Expense description",
	expense_type: "Expense type",
	expensetype_name: "Expense type",
	expensegroups: "Expensegroups",
	expenseitems: "Expenseitems",
	expenses: "Expenses",
	expenses_by_month: "by month",
	expenses_by_type: "by expense type",
	expenses_report: "Expenses report",
	expensetype: "Expense type",
	expensetypes: "Expense types",
	expertise: "What is your expertise?",
	expired_password: "Your password has expired",
	export: "Export",
	export_to_doc: "Export to",
	export_to_heshin: "Export to Heshin",
	export_to_movein: "Export to Movein",
	eye_contact: "Eye_contact",
	facebook: "facebook",
	facebook_url: "https://www.facebook.com/groups/656857967728173/",
	family_name: "Last Name",
	family_status: "Family status",
	fax: "Fax",
	female: "Female",
	field_id: "Field identification",
	field_must_be_unique: "The name already exists",
	field_name: "Field name",
	file_content: "File content",
	file_format: "File format",
	file_name: "Name of downloaded file",
	file_type: "File type",
	files_to_import1: '* choose "csv" or "xlsx". ',
	files_to_import2: 'The encoding of the csv file should be - "utf8".',
	files_to_import3: "The headers line should be deleted before the import.",
	financial: "Financial",
	finish: "Finish",
	finished: "finished",
	finresource_num: "Document num",
	first_invoice_num_error: "First invoice number should not be 0",
	first_invoice_num: "First number of the invoices series",
	first_credit_invoice_num: "First number of the credit invoices series",
	first_credit_justinvoice_num: "Frst number of the credit invoices series",
	first_credit_receipt_num:
		"Please enter the first number of the credit receipts series",
	first_name: "First Name",
	first_proforma_num:
		"In order to start using the proformas module you must enter the serial number of your first proforma</br>If you leave it zero the first number will be 1",
	first_justinvoice_num:
		"In order to start using the invoices module you must enter the serial number of your first invoice</br>If you leave it zero the first number will be 1",
	first_receipt_num:
		"In order to start using the receipts module you must enter the serial number of your first receipt</br>If you leave it zero the first number will be 1",
	first_time:
		" In order to start working with this app you must enter some values to the marked(red border) fields ",
	first_use: "First use",
	followup: "Follow up",
	followupserie: "Calendar planning",
	followup_character: "Followup character",
	followups: "Followups",
	followups_by_date: "by dates",
	followups_by_month: "by month",
	followups_by_year: "by year",
	followups_descriptions: "followups with meetings description",
	followups_explanation: "Followups explanation",
	followups_memorandum: "followups with meetings personal memorandum",
	followups_report: "Followups report",
	followuptype: "Followup type",
	followuptype_name: "Meeting type",
	followuptype_id: "Followup type",
	followuptypes: "Followup types",
	footer: "Footer",
	footer_text: "Footer text",
	for: "For",
	for_internal_use_only: "For internal use only",
	for_payment: "הסכום לתשלום בשקלים חדשים",
	foreign: "Foreign",
	foreign_bank: "Foreign bank",
	forever: "Forever",
	forgot_password: "» Forgot password",
	"forgot_your_password?": "» Forgot your password?",
	form: "Form",
	new_form: "Form",
	formula: "Formula",
	free_form: "not from list",
	frequency: "Frequency",
	frequency_unit: "Frequency unit",
	friday: "Friday",
	from: "From",
	from_date: "From date",
	date_from: "From date",
	from_patient: "From patient",
	from_user: "From user",
	full_configuration: "Full Configuration",
	gender: "Gender",
	general: "General",
	generalDescription: "General Description",
	generalDescription_desc:
		"מראה תואם לגיל, הופעה מסודרת, יצירת קשר עין תואמת, תנועתיות תקינה, שתוף פעולה מלא",
	generate_header: "Generate header from your data",
	getting_started: "Getting started",
	go_back: "Go Back",
	go_to: "go to",
	go_to_software: "To start working",
	greeting: "greetings",
	group: "Group",
	group_attendancy: "Attendancy",
	group_details: "Group details",
	group_short: "Group",
	group_type: "Group type",
	groupapatient: "groupapatient",
	groupattend: "meeting attendant",
	groupattends: "meeting attendants",
	grouppatient: "Therapy member",
	grouppatients: "Therapy members",
	groups: "Groups",
	groups_long: "Groups, Families,couples",
	hallucinations: "Hallucinations",
	hallucinations_desc: "נשללו במפורש, ללא התנהגות המתאימה להזיות",
	hanetunim_hufku: "The data has been generated",
	"has expired": "has expired",
	has_no_email: "has no email, the invoice will download instead",
	"has_received_a_request_to_reset_the_password_for_your_account._if_you_did_not_request_to_reset_your_password,_please_ignore_this_email":
		"has received a request to reset the password for your account. If you did not request to reset your password, please ignore this email",
	hashav: "Accountacnt software parameters",
	hashav_params: "accountant software - parameters",
	hayavot: "Exempt Securities(no vat)",
	header: "Header",
	header_default: "Default for document",
	header_text: "Header text",
	headings_signatures: "Headings & signatures",
	headtures: "headers & signatures",
	health_basket: "Health basket",
	heb: "Hebrew",
	hello: "Hello",
	hi_i_am_here: "Hi I'm here",
	hidden: "Hidden",
	hide: "Hide",
	historical: "historical",
	history_diagps: "Historical diagnosis",
	history_medics: "Historical medications",
	history_nondrugs: "Historical treatments",
	holiday: "Holiday",
	holiday_evening: "Holiday evening",
	holidays: "Holidays",
	"holidays-old": "Holidays",
	home: "Home",
	home_page: "Home page",
	house: "House",
	how_can_we_help: "How can we help? ",
	how_it_work: "How it works",
	how_it_works_text:
		"  The software includes a response to the following issues :<br>  Calendaring . <br>  Managing medical records ( records ) investment of time and effort Mizariim ( minutes per day ) <br>  Log physician filled automatically . <br>  Card indexes treated . <br>  Phonebook and emails . <br>  Management and writing documents . <br>  Managing reminders . <br>  Management fees and payments convenient for each patient . <br>  Computerized invoicing ( as required by the tax authorities ) . <br>  Easy deposit of checks . <br>  Full financial management , including accounting for payments to the authorities . <br>  In addition <br>  All information is stored in encrypted form , accessible only to the analyst himself . <br>  Automatic backup service . <br>  Access from anywhere via computer, tablet or mobile phone . <br>",
	hp: "HP",
	hp_long: "HP",
	hsb: "Hashavshevet",
	html_autotext: "Text",
	html_content: "Text",
	i: "invoice",
	i_understand: "I understand",
	id: "ID",
	id_number_short: "Id",
	id_number: "Id number",
	add_id_number: "Add id number",
	identifier: "Phone or email?",
	// 'id_number': 'Identifier',
	image: "Image",
	images: "Images",
	add_image_to_document: "Attached images",
	imp_modal_title: "Choose a csv file to upload",
	imp_submit_value: "Upload",
	import: "Import",
	import_followups: "Import followups",
	import_patients: "Import patients",
	import_tags: "Import tags",
	in: "in",
	in_: "on-",
	in_common_to_all_the_languages: "In common to all the languages",
	in_stock: "In stock! Order now!",
	in_youtube: "My-Cliniq in YouTube",
	inclusive: "Inclusive",
	income: "Income/by payment method",
	income1: "Income",
	invoices_sub_user_report: "Income by therapists",
	income_by_payment_method: "Income by payment method",
	income_report_by_payment_method: "Income report by payment method",
	income_report: "Income report",
	admin_income_report: "My-cliniq - Income report -",
	income_tax: "Income tax",
	info: "info",
	info_tables: "Information Tables",
	insert_autotext: "Insert new text",
	insert_calendar_item_or_invoice: "Insert calendar item(or invoice)",
	insert_email: "Insert email",
	insert_expenses: "Insert expenses",
	insert_headture: "Insert new header & signature",
	insert_phone: "Insert phone",
	insert_status_form: "Insert status",
	insert_text: "Insert text",
	insert_with_no_breaks: "Insert meeting with no breaks",
	insight: "Insight",
	insight_desc: "קיימת",
	intake: "Intake",
	intelligence: "Intelligence",
	intelligence_desc: "שמורה",
	intro: "Intro",
	introduction: "introduction",
	invalid_email: "Invalid email",
	inviation_to_chat: "Inviation to chat",
	invoice: "Invoice & Receipt",
	justinvoice: "Invoice",
	against_justinvoice: "Againt invoice",
	against_proforma: "Againt proforma",
	against_proformas: "Againt proformas",
	against_receipt: "Against Receipt",
	against_receipts: "Against Receipts",
	receipt: "Receipt",
	admininvoice: "Invoice",
	invoice_and_receipt: "Invoice/Receipt",
	invoice_by_mail: "Accepts invoice by email",
	automatic_proforma: "Automatic monthly proforma",
	invoice_copy: "Invoice copy",
	invoice_details: "Invoice details",
	invoice_enclosed: "Invoice enclosed",
  credit_invoice_enclosed: 'Credit invoice anclosed',
  invoice_header: "Invoice header",
	invoice_id: "Admin invoice id",
	external_invoice_id: "external_invoice",
	invoice_num: "Invoice number",
	invoice_preview: "Preview invoice/receipt before save and print",
	invoice_short: "Invoice & more",
	justinvoice_preview: "Preview invoice before save and print",
	justinvoice_short: "Invoice",
	receipt_preview: "Preview receipt before save and print",
	receipt_short: "Receipt",
	invoice_template: "Invoice template",
	invoicelines: "Invoice lines",
	invoices: "Invoices",
	"invoices_&_expenses": "Invoices & Expenses",
	invoices_by_date: "Invoices by dates",
	invoices_by_month: "Invoices by month",
	invoices_by_year: "Invoices by year",
	irs: "קובץ אחיד",
	irs_journal_report: "Journal report for taxes authorities",
	is_all_day: "is All day",
	is_decimal: "A number should be entered",
	is_default: "id Default",
	is_empty: "*fieldname*  is empty",
	is_foreign: "Foreign",
	is_greater: "Greater than???",
	is_holiday_evening: "Holiday evening",
	is_not_checked: "Nothing checked",
	is_not_password:
		"The Password should be at least one number, one lowercase and one uppercase letter and at least six characters long",
	is_not_user_name:
		"The user name must contain letters and/or numbers but not special characters",
	is_number: "Should be a number",
	is_past_addition_title: "This insert is recorded by its time",
	is_smaller: "Smaller than",
	is_valid_value: "a value must be chosen/or insert new",
	is_valid_value_patient_contact:
		"To add a new patient/contact prees on the plus icon on the right",
	is_zero: "should not be zero",
	it_usually_takes_about_30_seconds: "It usually takes about 30 seconds",
	item: "Patients & more",
	item_name: "Name",
	items: "Items",
	items_patient_contactway: "items in contactway list",
	items_patient_document: "items in document list",
	items_patient_email: "number of items in patient emails list",
	items_patient_phone: "number of items in patient phones list",
	iterations: "Iterations",
	iw: "Hebrew",
	joining: "Joining",
	journal: "Daily report",
	follow_ups_report: "Journal report",
	follow_ups_report1: "meetings",
	journalentries: "Journal entries",
	judgement: "Judgement",
	judgement_desc: "תקין",
	just_this_followup: "Just this followup",
	kind_regards: "Kind regards",
	is: "is",
	l: "letter",
	labtest: "Lab test",
	labtest_id: "Lab test",
	labtest_name: "Lab test name",
	labtestpatient: "Lab test for patient",
	labtestpatients: "Lab test for patients",
	labtests: "Lab tests",
	language: "Language",
	first: "First",
	last: "Last meeting",
	last_followup: "Last followup",
	last_login: "Last login",
	last_meeting: "Last meeting",
	last_updated: "Last Updated",
	last_update: "Last Update",
	last_year: "Last Year",
	lead: "Lead",
	lead_message: "Message",
	lead_message_confirmation: "Message confirmation",
	leadsite: "a Lead website",
	leadsites: "Lead websites",
	left: "Left",
	length: "Length",
	length_password: "The password length",
	length_user_name: "The user name length",
	letter: "Letter",
	medical_letter: "Medical Letter",
	letter_export: "Export to document",
	letters: "Letters",
	level: "Level",
	license_number: "license number",
	list: "List",
	list_autotexts: "Texts list",
	listen: "Listen",
	live_note: "Live note",
	loading: "Loading",
	local: "Local",
	login: "Login",
	logo: "Logo",
	logout: "Logout",
	logs: "Logs",
	long: "Long",
	long_live: "Long live the revolution,",
	made_by_admin: "Made by admin",
	made_by_user: "Made by user",
	main: "Main",
	male: "Male",
	marital_status: "Marital status",
	parents_marital_status: "Marital status of parents",
	married: "Married",
	mass_edit: "Mass Edit",
	max_size_is_6MB: "The maximum file size aloud hers is 6MB",
	medic: "Medication",
	"medic-name": "Medication name",
	medic_amount: "amount",
	medic_name: "Medicine",
	medical_conf: "Medical Confidential",
	default_medical_conf: "Medical Confidential as default",
	medical_data: "Medical data",
	medical_file: "Medical file",
	medical_record: "Medical record",
	medical_record_title: "Medical record",
	medication_name: "Medication name",
	medications: "Medications",
	medicine: "Medicine",
	medicpatient: "Medications for patient",
	medicpatients: "Medications for patient",
	medicppatients: "Medicppatients",
	medics: "Medications",
	meeting: "Meeting",
	meeting_configuration: "Meeting configuration",
	meeting_day: "On meeting_day",
	meeting_description: "Meeting description",
	meeting_ends_at: "The previous meeting ends at :",
	meeting_length: "Meeting length",
	meeting_of_the_day: "Meeting of the day",
	meeting_on_holiday: "Set a meeting a holiday",
	meeting_on_holiday_add_edit:
		"A change or an insert of a meeting during vacation is one time",
	meeting_reminder_vacation_message: "Meeting/Reminder/Vacation/Message",
	meeting_text: "Default Text",
	meeting_time: "Meeting time",
	meeting_title: "Meeting title",
	vacation_title: "Vacation description",
	meetingtype: "Meeting type",
	meetingtype_name: "Meeting type",
	meetingtype_price: "Meeting price",
	meetingtype_id: "Meeting type",
	meetingtypes: "Meeting types",
	membership_auto_renew: "Membership auto renew",
	memo: "Memo",
	memory: "Memory",
	memory_desc: "Normal in all its ranges",
	reality_check: "Reality chaeck",
	reality_check_desc: "Normal",
	memos: "Memos",
	menu: "Menu",
	message: "Message",
	min: "Min",
	minute: "Minute",
	minutes: "Minutes",
	misc: "Misc",
	model_name: "Document",
	monday: "Monday",
	month: "Month",
  months: "Months",
  and: "and",
	month1: "Every month",
	month2: "Every 2 months",
	month_before: "Previous month",
	monthly: "Monthly",
	monthly_expenses: "Monthly expenses",
	more: "More information",
	more_details: "More details",
	more_details_inside: "More details inside",
	more_information: "More information",
	move_to_the_next_tab: "move to the next tab - ",
	movement: "Movement",
	must_be_positive_number: "Should be a number greater than zero",
	must_be_not_negative: "Should not be a negative number",
	my_account: "My account",
	my_page: "My page",
	my_settings: "My settings",
	n: "No",
	name: "Name",
	display_name: "Name",
	name_en: "English name",
	name_of_business: "שם בית העסק",
	name_or_email: "User name or Email",
	login_string: "User name or Email",
	need_help: ' <a href="mailto:mycliniq.manage@gmail.com">Need help?</a>',
	network_error: "NETWORK ERROR PLEASE RELOAD",
	never: "Never",
	new: "New",
	new_billing_api_message: "New billing message",
	new_image: "New image",
	new_item: "New item",
	new_nondrug: "New nondrug",
	new_password: "New password",
	new_user_register: "» New here? enter and register! ",
	existing_user_login: "» Already registered? Go straight to login ",
	new_value_must_be_greater_than_old_value:
		"THe new value must be greater than the old value",
	next: "Next",
	next_followup: "Next followup",
	next_meeting: "Next meeting",
	next_meeting_at: "Next meeting at",
	next_meeting_strats_at: "The next meeting strats at",
	next_pay: "Next pay",
	no: "No",
	"no change existing_default": "Leave default as is",
	no_breaks: "None",
	no_cancellation: "No cancellation",
	no_change_in_followups: "Update is not allowed",
	no_charge: "No charge",
	no_lines: "There are no lines",
	no_medical_conf: "none",
	no_partner: "No partner",
	no_program: "No program",
	no_repeat: "No repeat",
	no_result: "No result",
	no_result_to_insert_new_click_plus: "No result!",
	no_rows_yet: "No rows yet",
	no_template_design: "No template design",
	no_user: "Not authorized",
	no_valid_data_found: "No valid data found",
	non_conflict: "Non conflict",
	nondrug: "Non drug",
	nondrug_free_form: "not from list",
	nondrug_name: "Treatment",
	nondrugpatient: "Treatment for patient",
	nondrugpatients: "Treatments for patient",
	nondrugs: "Rreatments",
	none: "none",
	none_active: "None active",
	nonee: "none",
	normal_range: "Normal range",
	not: "Not",
	not_active: "Not active",
	pending: "Pending",
	not_charged: "Not charged",
	not_identicalToPassword: "Passwords are not identical",
	not_invoice_by_mail: "No",
	not_paid_yet: "Not paid yet",
	not_payd: "Not payd",
	not_permanent: "not Permanent",
	not_registered_yet: "Not registered yet",
	not_takes: "Not takes",
	not_takes_place: "Not takes place",
	note: "note",
	notes: "Notes",
	vacation_notes: "Vacation notes",
	notification: "notification",
	notification_default: "Notification default",
	notification_time: "time to notify",
	notifications: "Notifications",
	notifiy: "notifiy",
	now_on: "All meetings from now on",
	now_until_date: "meetings from now Up to (incl.)",
	number: "Number",
	number_of_items_in_patient_email: "number of items in patient email",
	number_of_items_in_patient_phone:
		"number_of_items_in_patient_phoneמספר הסוגים ברשימת הטלפונים למטופל",
	number_of_kids: "Number of kids",
	number_to_create: "Number to create",
	ohoops_error:
		"Ohoops, it looks like we have an error. It has been reported to our technical team.",
	mr: "L.N.",
	om: "OM",
	om_long: "OM",
	ngo: "N.G.O.",
	ngo_long: "N.G.O.",
	on: "on ",
	once: " Once",
	one_of_them_should_be_filled: "Both names are empty",
	one_time: "Once",
	one_time_appointment: "One time appointment",
	"one_time_appointment_-_no_series": "One time appointment - no series",
	only: "Only",
	only_one: "Only this meeting",
	only_one_of_them_should_be_filled: "Only one name please",
	op: "OP",
	op_long: "OP",
	open: "Open",
	opt_csv: "csv",
	csv: "csv",
	opt_dat: "dat",
	opt_other: "other",
	opt_pdf: "pdf",
	opt_xlsx: "xlsx",
	xlsx: "xlsx",
	or: "Or",
	or_any_other_generic: "* Or any other Generic...",
	order: "Order",
	order_of_appearance: "Order of appearance",
	order_num: "Order of appearance",
	question_num: "Question number for scales",
	orderedList: "Ordered list",
	organization: "Organization",
	organized: "Do you consider yourself an organized person?",
	orientation: "Orientation",
	orientation_desc: "תקינה במקום בזמן ובזהות",
	orientation_in_place: "Orientation_in_place",
	orientation_in_time: "Orientation_in_time",
	original: "Original",
	osek: "מספר ע.מ.",
	other: "Other",
	other_meeting: "Other meeting",
	otp: "One time password",
	otp_token: "One time password",
	"otp-needed": "otp needed",
	otp_needed: "otp needed",
	out_of: "Out of",
	output: "Output",
	ovelapping_followupseries: "Ovelapping followups?",
	owner: "Owner",
	p: "prescription",
	page: "Page",
	page_not_found: "Page not found",
	paginate_normal: "default pagination",
	paid: "Paid",
	paid_by: "Paid by",
	paid_by1: "Paid by",
	client_paid: "paid.",
	paid_by_invoice: "Paid by document",
	paid_by_finresource: "Paid by document",
	part: "part",
	part_configuration: "important Configuration",
	password: "Password",
	password_change: "Password Change",
	password_confirmation: "Password confirmation",
	password_current: "Current Password",
	password_new: "New Password",
	password_new_confirmation: "New Password 2nd Confirmation",
	password_old: "Old Password",
	password_reset: "Password Reset",
	password_successfully_updated: "Password successfully updated",
	password_was_changed: "Password was changed",
	passwords_mismatch: "Passwords mismatch",
	passwords_not_matching:
		"Your password and confirmation password do not match.",
	path: "Path",
	patient: "Patient",
	patient_file: "Patient file",
	patient_contact: "Patient or contact",
	patients_plus: "Patients & more",
	patient_contact_or_meeting: "A meeting with...",
	patient_data: "Data of the patient",
	patient_details: "Patient details",
	patient_name: "Patient name",
	patient_or_contact: "Patient/Contact",
	patient_or_payor: "Patient/Payor",
	patient_page: "Patient page",
	patient_related_tables: "Patient related tables",
	patient_type: "Patient type",
	patients: "Patients",
	payorpatients: "Patients",
	patients_explanation: "Patients explanation",
	patients_list: "Patients list",
	patients_medications: "Patients medications",
	patienttype: "patient type",
	patienttype_id: "Patient type",
	patienttypes: "patient types",
	pay_page: "Pay page",
	pay_with_bitcoin: "Pay with Bitcoin",
	payed: "Payed",
	paying: "Paying",
	paying_method: "Paying method",
	payment: "Payment",
	payment_description: "Payment for using the software",
	payment_details: "Period",
	in_period: "In the period",
	there_were: "There were",
	there_was_one: "There was one meeting",
	payment_failed:
		"The payment has failed,<br> please retry, after refreshing the page",
	payment_failed1: "The payment has faled please refresh the page and retry",
	payment_method: "Payment method",
	payment_mode: "Payment mode",
	payment_report: "Payment report",
	payment_successful: "Payment successful!",
	payment_successful1: "an invoice will be emailed to you.",
	payment_successful2: "You can track your payments on your user page.",
	payment_type: "Payment type",
	paymentapp: "App",
	paymentapp_id: "App",
	paymentmethod: "Paymentmethod",
	payments: "Payments",
	payor: "Payor",
	payor_name: "Payor",
	payor_id: "Payor",
	payor_details: "Payor in details",
	payorprice: "Payor prices",
	payorprices: "Payor prices",
	payorprices_short: "prices",
	payors: "Payors",
	paypal: "Paypal",
	pdf: "Download as pdf",
	per_month: "Per user Per month",
	per_year: " Per year",
	percent: "Percent",
	percent_for_calculation: "percent for calculation",
	percent_for_tax: "Percent for tax",
	percent_for_vat: "Percent for vat",
	percentage: "Percentage",
	period: "Period",
	permanent: "Permanent",
	permanent_usag: "Permanent usag",
	permanent_usage: "Permanent usage",
	permanent_remarks: "Permanent remarks",
	personal_details: "Personal details",
	personal_memorandum: "Personal memorandum",
	perut_on_bkmvdata: "פרוט סך סוגי הרשומות שנוצרו בקובץ",
	perut_tochna_vetaarich: "פירוט תוכנה ותאריך הפקה",
	phone: "Phone",
	"phone-whatsapp": "Phone/Whatsapp ",
	phone_choose_or_insert_new: "Choose or insert new phone",
	phone_description: "Phone description",
	phone_mobile: "Mobile",
	phone_mobile_invalid: "Mobile phone is incorrect",
	phones: "Phones",
	photo: "Photo",
	photos: "Photos",
	place_new_payment: "Place new payment",
	placeholder_title: "placeholder_titletranslation",
	plans_header:
		"We chose for you a fair and simple pricing plan,<br> so it can match your needs and work habits. Choosing a plan will allow you a quick and immediate integration into the system.",
	please_active_your_account_from_the_activation_email:
		"Please activate your account from the activation email",
	please_approve_saving_due_to_the_following_overlaping_followups:
		"Please approve saving the new followup with exceptions due to the following overlaping followups:",
	please_choose_name_column: 'Please select at least "name" column',
	please_choose_at_least_one:
		"Please check an email address. A new address can be added via the first row",
	please_click_here_to_go_to_form_to_reset_password:
		"Please click here to go to form to reset password",
	please_click_here_to_go_to_form_to_verify_patient:
		"Please click here to go to form to review and verify patient",
	please_click_on_the_link_below_to_complete_verification_of_your_email:
		"Thank you for registering. To finally activate your account please click the following link.",
	please_click_on_the_link_below_to_register:
		"In order to register please click the following link.",
	please_click_on_the_link_below_to_join:
		"In order to join please click the following link.",
	please_click_on_the_link_below_to_receive_patient_details:
		"In order to receive the patients details please click here.",
  please_click_on_the_link_below_to_enter_patient: 'In order to enter the patients file please click the following link.',
	please_click_to_change: "Please click here to change",
	"please_contact_support_for_assistance_at:":
		"Please contact support for assistance at:.",
	please_enter_the_verification_code_that_was_sent_to:
		"Please enter the verification code that was sent to your phone",
	please_enter_the_verification_code_from_the_app:"Please enter the verification code that appears on your authenticating app",
  check_your_2fa_app:"Please enter the verification code that appears on your authenticating app",

	please_enter_your_email_address_We_will_send_you_an_email_with_instructions_to_reset_your_password:
		"Please enter your email address We will send you an email with instructions to reset your password",
	please_enter_your_email_address_we_will_send_you_an_email_with_instructions_to_reset_your_password:
		"Please enter your email address We will send you an email with instructions to reset your password",
	please_enter_your_email_address_we_will_send_you_an_email_to_user:
		"Please enter your email address, and we shall email you the instructions, only after verifying with your therapist",
	please_find_attached_your_document: "Please find attached a document",
	please_find_attached_your_finresources: "Please find attached your documents",
	please_find_attached_your_invoice: "Please find attached your invoice from ",
	please_find_attached_your_receipt: "Please find attached your receipt from ",
	please_find_attached_your_prescription:
		"Please find attached your prescription",
	please_find_attached_your_proforma: "Please find attached your proforma",
	please_find_attached_your_patient_proforma:
		"Please find attached your proforma",
	your_patient_proforma: "Your proforma.",
	please_follow_the_instructions_we_sent_to:
		"A password reset email has been sent to your e-mail address",
	please_insert_only_number: "Please insert only number",
	please_leave_contact_details:
		"You may leave your message and conttact details here: ",
	please_login_or_register_to_proceed: "Please login or register to proceed",
	please_note_date_past:
		"Please note that you are adding or editing a day in the _past",
	please_note_num:
		"If you are changing your vat number you should also update your invoice header",
	please_resend_me_activation_email: "Please resend me activation email",
	please_select: "Please_select",
	please_select_plan: "Please select plan",
	please_wait_while_we_create_your_account:
		"Please wait while we create your account",
	plus_vat: "plus vat",
	postponed: "Postponed",
	posts: "Posts",
	prescription: "Prescription",
	add_prescription: "Prescription",
	prescription_header: "Prescription header",
	prescription_no: "Prescription no.",
	prescription_preview: "Prescription preview",
	prescription_template: "Prescription template",
	prescriptions: "Prescriptions",
	preview: "Preview",
	to_preview: "Preview",
	previous: "Previous",
	previous_month: "Previous Month",
	previous_page: "Previous page",
	previous_password:
		"The new password must be different from your old passwords",
	price: "Price",
	price_bought: "Price that we bought",
	price_for_meeting: "Price for meeting",
	price_for_patient: "Price for patient",
	price_in_us_dollar: "Price in US dollar",
	price_in_us_dollars: "Price in us dollars",
	price_paid: "Price Paid",
	price_patient: "price for patient",
	price_payor: "Payor price",
	price_update_will_change_the_whole_serie_and_followup_type:
		"* The price update will change the price for all future meetings of the same type.",
	pricing: "Pricing",
	pricing_and_patient_type: "Pricing and patient type",
	print: "Print",
	print_and_download: "Print & download",
	print_copy: "Print copy",
	print_invoice: "Print invoice",
	print_proforma: "Print proforma",
	privacy: "Privacy terms",
	privacy_policy: "Privacy Policy of this website.",
	private: "Private",
	profession: "Profession",
	profitloss_report: "Profit and loss report",
	profitloss: "Profit and loss",
	profile: "Profile",
	profile_photo: "Profile photo",
	proforma: "Proforma",
	proforma_to_patient: "request for payment",
	proforma_id: "proforma_id",
	proforma_num: "Proforma number",
	justinvoice_num: "Invoice number",
	receipt_num: "Receipt number",
	proforma_preview: "Preview proforma before save and print",
	proforma_short: "proforma",
	proformalines: "proforma lines",
	proformas: "Proformas",
	proformas_by_date: "proformas by date",
	proformas_by_month: "proformas by month",
	proformas_by_year: "proformas by year",
	proformas_report: "Proformas list",
	justinvoices_report: "Invoices list against receipts",
	justinvoices: "Invoices list",
	program: "Program",
	program_id: "Program",
	programs: "Programs",
	pturot: "Exempt transactions or 0 value",
	public_site: "my-cliniq.com",
	publice: "Marketing",
	purchase: "purchase",
	purchase_details: "Purchase Details",
	purchase_history: "Purchase history",
	purchase_id: "Purchase id",
	purchases: "Purchases",
	purchases_summary: "Purchases Summary",
	put_this_purchase_under: "Put this purchase under",
	quantity: "Quantity",
	question: "Question",
	questions: "Questions",
	read_more: "Read More",
	recent: "recent",
	receipt_doc: "Receipt",
	recipient: "Recipient",
	recipients: "Recipients",
	record_code: "Record code",
	record_description: "Record description",
	refresh: "Refresh",
	reg: "regarding",
	reg_no: "registration number",
	register: "Register",
	register_text1: "Cloud clinic management system",
	register_text2:
		"The system was developed by and for therapists, so that you can devote your full attention to your patients",
	register_text3: "Production of invoices",
	register_text4: "Meeting summaries that are saved automatically",
	register_text5: "Patient files",
	register_text6: "meeting diary",
	register_text7: "Smart reports",
	register_text8: "One month free trial",
	register_text9: "and then 79 NIS + VAT per month",
	register_text10: "No patient quota",
	register_text11: "Unlimited storage volume",
	register_text12: "No hidden costs",
  register_new_text1: 'You have won a month of free use of the worlds best clinic management system',
  register_new_text2: 'All thats left to do is click the button:',
  register_new_text3: 'To verify your account »',
  register_new_text4: 'What happens next month?',
  register_new_text5: 'After the trial period ends, you will be sent a secure payment link.',
  register_new_text6: 'The monthly cost is ₪79 per user, plus VAT.',
  register_new_text7: 'What about invoices? They are an additional fee, right?',
  register_new_text8: 'The invoices are included in the price. Also the reminder messages, questionnaires, letters, prescriptions and the landing page for scheduling appointments.',
  register_new_text9: 'How do I get started?',
  register_new_text10: 'We recommend starting small and adding one appointment - just to see how easy it is - by clicking on the calendar on the right of the login page. There is no need to add patients in advance - you can add a patient when adding the appointment. After that, you can record the course of the appointment in the medical record field, in the center of the home page. The patient card will appear on the left.',
  register_new_text11: 'Sounds good, but I didnt understand how to use it. How can I learn?',
  register_new_text12: 'On our website you can find explanations about',
  register_new_text13: 'Meeting documentation on the home page',
  register_new_text14: '- You can do almost everything through it',
  register_new_text15: 'There are also guides about',
  register_new_text16: 'The meeting diary',
  register_new_text17: 'and',
  register_new_text18: 'Invoice generation and financial management.',
  register_new_text19: 'In addition, you can find on the site a page',
  register_new_text20: 'of FAQ',
  register_new_text21: 'and we also have',
  register_new_text22: 'a YouTube channel',
  register_new_text23: 'With instructional videos.',
  register_new_text24: 'I don\'t want to read or waste time on YouTube. Can someone guide me?',
  register_new_text25: 'Sure. You can ask any question',
  register_new_text26: 'On our support WhatsApp',
  register_new_text27: 'Or arrange a Zoom tutorial by',
  register_new_text28: 'Filling in the details here',
	registration_successful: "registration successful!",
	regular: "Meeting",
	reject: "Reject",
	related_data: "Related data",
	remarks: "Remarks",
	remark: "Remark",
	no_answer: "Remark",
	"remember password :": "Remember password :",
	reminder: "Reminder",
	reminder_description: "Reminder description",
	reminders: "Reminders",
	remove: "Remove",
	remove_invoiceline: "Remove invoice line",
	remove_proformaline: "remove proforma line",
	renew: "Renew",
	repayment_date: "Repayment date",
	repeat: "Repeat",
	repeat_days: "Repeat days",
	replace_photo: "Replace photo",
	reply_to: "Reply",
	reply: "Reply",
	to_reply_reply_to: 'to Reply to this email please select "reply to"',
	to_reply_reply_to1: 'to Reply to this email please select "reply to"',
	report: "Report",
	report_problem: "Report a problem",
	reports: "Reports",
	reprint: "Reprint damaged copy",
	requested_time: "The requested time",
	resend_activation: "Resend activation",
	reset: "Reset",
	reset_password: "Reset Password",
	reset_password_for: "Reset Password for",
	reset_password_now: "Reset password now",
	result: "Result",
	role: "Role",
	roles: "Roles",
	route_of_admin: "Route of admin",
	row_actions: "Actions",
	actions: "Actions",
	saturday: "Saturday",
	save: "Save",
	save_and_select_template: "Save and Select a template",
	save_as_draft: "Save as draft",
	save_failed: "Save failed",
	save_followupserie_with_overlap_exceptions:
		"Save followup with overlap exceptions",
	save_only: "Save draft",
	save_tempalte: "Save tempalte",
	saved_tempalte: "The tempalte was saved",
	save_with_exceptions: "Save with exceptions",
	saved_successfully: "Successfully saved",
	saving: "Saving ...",
	schema: "Schema",
	search: "Search",
	search_for_user: "Search for user",
	search_patient: "Search patient",
	seating: "Seating",
	second_email: "Second E-mail",
	second_phone: "Second Phone",
	see_all: "See all",
	see_all_patient_images: "Gallery",
	see_document: "See document",
	select: "Select",
	select_a_headture: "select a header & signature",
	select_a_template: "Select a template",
	select_all: "Select all ",
	select_all_fields: "Select all",
	select_email_phone: "Select email and phone",
	select_paln: "Select a paln",
	select_plan: "Select plan",
	select_to_whom_first:
		"first select please to whom the invoice will be isssued",
	select_a_password: "Please key in your own password and confirm it",
	selected: "Selected",
	self_collect: "Self collect",
	self_oriented: "Self_oriented",
	self_reminder: "Self reminder",
	send: "Send",
	send_by_email: "Send by email",
	send_emails: "Send email",
	send_original_to_user: "Send original to user",
	send_to_this_email_every: "Send to this email every document",
	send_users_email: "Send an email to users",
	sendgrid_status: "Send status",
	sending_a_text_message_to_your_mobile_phone_with_a_verification_code:
		"Sending a text message to your mobile phone with a verification code.",
	sent_successfully: "Sent successfully",
	sent_to: "Sent by Email",
	series_2weekly: "2w",
	series_3weekly: "3w",
	series_daily: "Daily",
	series_exception: "Exception",
	exception: "Exception",
	series_for_hashav_contact: "Series for acountannt numbers - contact",
	series_for_hashav_patient: "Series for acountannt numbers - patient",
	series_for_hashav_payor: "Series for acountannt numbers - payor",
	series_monthly: "Monthly",
	series_repeat: "Repeat",
	series_weekly: "Weekly",
	server_error: "due to computer sleep, the website needs refresh",
	set: "סט",
	set_contact_types: "Set contact types",
	set_default_length: "Set default length",
	set_followup_types: "Set followup types",
	set_patient_types: "Set patient types",
	setting: "Setting",
	setting_explanation: "Setting explanation",
	settings: "Settings",
	short: "Short",
	show: "Show",
	show_manual_charge: "Show manual charge",
	show_payment_details: "show payment details",
	invoice_per_followup: "Attach payment to meeting",
	show_payment_details_sum_in_invoice:
		"Show payment details and sum in invoice",
	show_signature_header: "Edit header and signature",
	show_versions: "Show versions",
	show_versions_on_followup: "Show versions on follow up",
	show_personal_memorandum: "Personal Memorandum",
	show_personal_memorandum_on_mobile: "Show Personal Memorandum on Mobile",
	show_patient_id: "show patient id on search",
	show_insert_row: "Show insert row",
	sign_up: "Sign Up",
	signature: "Signature",
	signature_text: "Signature",
	simulator: "Simulator",
	simulator__ip: "Simulator  ip",
	simulator__token: "Simulator  token",
	simulator__user_id: "Simulator  user id",
	simulator__user_name: "Simulator  user name",
	simulators: "Simulators",
	single: "Single",
	site_description: "crm and clinic management",
	site_kew_words:
		"psychology, psychiatry, health care, clinic, קליניקה, פסיכולוגיה",
	site_logo: "Site Logo",
	site_name: "my-cliniq.com",
	site_slogan: "Just run your cliniq",
	site_team: "My-Cliniq",
	size: "Size",
	sms: "Sms",
	smses: "Text messages",
	social_security: "Social security",
	"sorry,_validation_error": "Sorry, validation error",
	"sorry,_you_have_not_made_any_orders_yet":
		"Sorry, you have not made any orders yet",
	"sorry,no_result_match": "Sorry, no result match",
	sorry_error:
		"We are sorry, it seems that an error occurd, the details were emailed to us",
	sort: "Sort",
	sort_by: "Sort by",
	source_doc_types: "Document types",
	start: "Start",
	start_date: "Start date",
	start_date_end_date: "Start date - End date",
	start_on: "starts on ",
	start_paying_first: "My-Cliniq - End of free period start payment 1st notice",
	start_paying_second: "My-Cliniq - start payment 2nd notice",
	ot_payment: " My-Cliniq - Start payment",
	ot_second_payment: "My-Cliniq - Start payment 2nd notice  ",
	start_time: "Start time",
	start_time_hour: "start time",
	end_time_hour: "end tome",
	day_time_range: "please select the time range of availability on ",
	start_typing_here: "Start typing here",
	status: "Status",
	status_name: "status name",
	statuses: "Statuses",
	stock: "Stock",
	stop_auto_renew: "Stop auto renew",
	stop_repeat_after: "Stop repeat after",
	street: "Street",
	style: "Style",
	sub_total: "Sub Total",
	subject: "Subject",
	submit: "Submit",
	submit_invitation: "Submit invitation",
	submit_question: "Submit question",
	submit_report: "View",
	success: "Success",
	suicidality: "Suicidality",
	suicidality_desc: "נשללה במפורש, ללא עדות לסיכון אובדני",
	sum: "Sum",
	sum_of_line: "Sum of line",
	expense_without_vat: "Sum without vat",
	sums_by: "sums by",
	sunday: "Sunday",
	su: "Su",
	mo: "Mo",
	tu: "Tu",
	we: "We",
	th: "Th",
	fr: "Fr",
	sa: "Sa",
	supplier: "Supplier",
	supplier_id: "Supplier",
	supplier_invoice: "Supplier invoice",
	supplier_name: "Supplier name",
	suppliers: "Suppliers",
	suspended: "Suspended",
	table: "Table",
	table_of_diagnosis: "Table of diagnosis",
	table_of_lab_tests: "Table of lab tests",
	table_of_medications: "Table of medications",
	table_of_nondrugs: "Table of nondrugs",
	tag_updated: "Tag was updated",
	tag: "Tag",
	tags: "Tags",
	taken_for: "Taken for",
	takes_place: "Takes place",
	tax: "Tax",
	tax_deductible: "Tax deductible (inc. unrecognized vat",
	expense_recognized_for_tax: "Tax deductible (inc. unrecognized vat",
	tax_deductible2: "Tax deductible (without unrecognized vat",
	expense_recognized_for_tax2: "Tax deductible (without unrecognized vat",
	tax_details: "Tax details",
	tax_doc: "invoice",
	tax_number: "Tax number",
	tax_on_transcations: "המס על עסקאות",
	tax_percent: "Tax recognised",
	expensetype_vat_percent: "Vat recognized",
	expense_vat_recognized: "Vat recognized",
	tax_receipt_doc: "Invoice / Receipt",
	tax_refund_doc: "Credit invoice",
	tax_tsumot: "מס תשומות על ציוד ונכסים קבועים",
	tax_tsumot_other: "מס תשומות על אחרות",
	tax_year: "שנת המס עליה הופקו הנתונים",
	taxes: "Taxes",
	taxes_calc: "Taxes calculations",
	taxes_report: "Taxes report",
	temp_announcement: "Temp_announcement",
	temp_announcement_sub_user: "Temp_announcement",
	temp_announcement_potential: "Temp_announcement",
	tempalte_number: "Template",
	template: "Template",
	"template.document": "Template",
	template_title: "Template title",
	terms: "Terms",
	terms_of_service: "Terms of service,",
	agree_to_terms: "accept the Terms of service",
	test: "Questionnaire",
	test_association: "Questioneer association",
	new_test: "New Questionnaire",
	test_name: "Questionnaire name",
	test_submit: "Submit Questionnaire",
	tests: "Questionnaires",
	teudat_rishum: "license number",
	text_onetime_paying_first_1:
		"The free period of My-Cliniq has come to an end.",
	text_onetime_paying_first_3:
		" So it is time to start paying. the monthly rate is ",
	text_onetime_paying_first_4:
		"₪ + vat, we believe that this price reflects our value",
	text_onetime_paying_first_5: "to safe payment >",
	text_onetime_paying_first_6:
		"As always, we are open to your thoughts an requests.",
	text_paying_1:
		" We are happy to inform you that your My-Cliniq trial has come to an end,",
	text_paying_2:
		"The monthly cost will be 79is. We believe that this price reflects the benfits of our software ",
	text_paying_4:
		"As always, we are open to your remarks, suggestions an complaints.",
	text_paying_first_1:
		"As we have informed you, your My-Cliniq trial has come to an end,",
	text_paying_first_2:
		"The monthly cost will be 79is. We believe that this price reflects the benfits of our software ",
	text_paying_first_2a:
		" בין אם מדובר <b>ברשומות רפואיות</b> דיגיטליות, <b>ביומן רופא המאושר על ידי רשות המסים</b> ומסונכרן עם מאזן החוב, או מערכת מתקדמת של <b>חשבוניות ירוקות</b> - אנחנו כאן כדי לחסוך בזמן,<br> לקצץ בבירוקרטיה ולהנמיך את כל הרעש שמסביב, כדי לאפשר לך להתרכז במה שחשוב באמת - הטיפול.",
	text_paying_first_3:
		"In  order to make the payment please click on the link below",
	text_paying_first_4:
		"If you liked us, we shall be happy to add you to our customers ",
	text_paying_first_5: "to safe payment >",
	text_paying_first_6:
		"As always, we are open to your remarks, suggestions an complaints.",
	text_paying_second_1:
		"As we have informed you, your My-Cliniq trial has come to an end,this a second notice",
	text_paying_second_2:
		"The monthly cost will be 79is. We believe that this price reflects the benfits of our software ",
	text_paying_second_2a: " ",
	text_paying_second_3:
		"In  order to make the payment please click on the link below",
	text_paying_second_4:
		"If you liked us, we shall be happy to add you to our customers",
	text_payinguser_invoice_short_first_6:
		"As always, we are open to your thoughts an requests",
	text_payment: "Thank you for your payment for the usage of ",
	text_ot_paying_first_1:
		"כפי שהודענו, תקופת ההרצה של <b>My-Cliniq</b> הסתיימה בסוף חודש מרץ.",
	text_ot_paying_2: "מעתה השימוש בתוכנה יהיה כרוך <b>בתשלום חודשי של",
	text_ot_paying_2a: ' פלוס מע"מ.</b>',
	text_ot_paying_3:
		"מחיר זה מקנה שימוש חופשי בשירותי התוכנה - ללא הגבלה וללא התחייבות. העלות כוללת הנפקת חשבוניות ירוקות, יומן רופא המאושר על ידי רשות המסים, מערכת ניהול פגישות ורשומות רפואיות, ומגוון דרכים אחרות לייעול הניהול השוטף של קליניקה.",
	text_ot_paying_4:
		"רצינו לנצל את ההזדמנות כדי להודות לך על ההשתתפות בתקופת הפיתוח הראשוני. למרות ש-<b>My-Cliniq</b> כבר לא בהרצה, אנחנו נותרים מחוייבים להמשך הפיתוח ולהוספת כלים חדשים. זוהי סופה של תקופה, אך גם תחילתה של דרך חדשה.",
	text_ot_paying_second_1:
		"כפי שהודענו, תקופת ההרצה של <b>My-Cliniq</b> הסתיימה בסוף חודש מרץ, זוהי הודעה שניה.",
	text_report: "Followups ",
	text_suspended:
		"My-Cliniq - End of free period start payment suspension notice",
	text_suspended_1:
		"text_suspended_1כפי שהודענו לך באימיילים קודמים, תקופת ההרצה של My-Cliniq הסתיימה.",
	text_suspended_2: "text_suspended_3עלותה החודשית של My-Cliniq תעמוד על ",
	text_suspended_3:
		'text_suspended_4₪ פלוס מע"מ. אנו מאמינים שמחיר זה משקף באופן הוגן את התועלת שמביאה התוכנה.',
	text_suspended_4: "to safe payment >",
	text_to_translate_in_english_invoice: "Texts to translate in english invoice",
	textvalue: "Textvalue",
	thank_you_for_your_registration: "!thank you for your registration",
	registration_wait:
		"on these moments we prepare your security code, please wait!",
	thanks_alot: "Thanks alot",
	thanks_we_have_received_your_message: "Thanks! We have received your message",
	the_original_message: "The original message",
	the_purchase_made_in: "The purchase made in",
	therapist: "Therapist",
	therapists: "Therapists",
	assoc_sub_user_ids: "Therapists",
	there_are_no_followups: "There are no follow ups",
	there_are_no_followups_past: "There were no follow ups",
	there_are_no_followups_future: "There are no follow ups yet",
	there_are_no_followups_but_fus_future:
		"There are will be follow ups when the future will become present",
	there_are_no_followups_present:
		"There are no follow ups today, you can go out for a walk",
	there_are_no_followups_click_to_set:
		"To add a new meeting click on the calendar",
	there_is_a_patient_by_that_name:
		"There is another patient by that name. Do you want to save this new patient anyway?",
	there_is_no_header:
		"There is no header with your details on the document. Insert header and signature in your configuration page",
	this: "This",
	this_action_requires_login: "This action requires login",
	this_document_has_been_signed:
		"this document has been signed. Do you want to use this as a template?",
	"this_document_has_been_signed_use_as_template?":
		"this document has been signed. Do you want to use this as a template?",
	this_event: "This event",
	this_is_from_taxes_table_from_the_user_data:
		"This is from taxes table from the user data",
	this_month: "MTD",
	patient_exists: "The patient exists",
	this_patient_exists_here: "This patient, is already in this group",
	"this_patient_exists_to_continue?":
		"There is a patient with the same name on your list, do you want to continue create another one?",
	this_week: "Week",
	this_year: "YTD",
	thoughtProcess: "thoughtProcess",
	thoughtProcess_desc: "intact",
	thursday: "Thursday",
	time: "Time",
	time_between: "The gap is :",
	time_from: "From time",
	time_on_holiday_evening: "End time on holiday evening",
	time_to: "Untill time",
	time_to_logout: "Time to logout",
	time_with_breaks_overlaps_next_meeting:
		"The meeting time including  breaks overlaps the next meeting",
	title: "Title",
	to: "to",
	to_be_calculated: "יש לחשב מס הכנסה לתשלום לאחר קיזוז ניכוי במקור",
	to_be_done: "To be done",
	to_continue: "To continue",
	to_continue_with_main: "To continue as private subscriber",
	to_continue_your_subscription: "To continue your subscription",
	to_date: "Untill date",
	to_payment: "Payment",
	to_secure_your_account:
		"* To secure your account, a second password will be sent to your mobile device the first time you login and from time to time.",
	to_terms: "to the Terms of service",
	today: "Today",
	token_used_or_not_found: "Token used or not found",
	toname: "addressed to",
	total: "Total",
	total_of: "Total of",
	totalMinusvat: "Total Minus Vat",
	total_admininvoice: "Total admininvoice",
	total_amount: "Total amount",
	total_debt: "Total debt",
	total_expense_recognized: "Total expense recognized",
	total_expense_vat_recognized: "Total expense vat recognized",
	total_expense_tax: "Total expense tax",
	total_expense_vat: "Total expense vat",
	total_expenses: "Total expenses",
	total_for: "Total for ",
	total_for_payment: "Total for payment",
	total_invoice: "Total invoice",
	total_items: "Total Items",
	total_lm_debt: "Last month",
	total_lm_paid: "Total paid last month",
	total_lm_prices: "total prices for ",
	total_lm_payor_prices: "total prices for payor for  ",
	total_minus_vat: "Total minus vat",
	total_monthly_debt: "this month",
	total_monthly_paid: "Total monthly paid",
	total_monthly_prices: "Total monthly prices",
	total_invoice_vat_exempt: "Total vat exempt",
	total_inc_vat: "total including vat without vat",
	total_paid: "Total paid",
	total_prices: "Total prices",
	total_records: "סך רשומות",
	total_tax: "Total tax",
	total_total_debt: "Total debt",
	total_total_credit: "Credit remainder",
	total_until_lm_debt: "total debt until ",
	total_users: "total Users",
	"total_invoice_vat ": "Total vat",
	total_without_vat: "Total without vat",
	total_with_vat: "Total with vat",
	total_yearly_debt: "Total yearly debt",
	total_yearly_paid: "Total yearly paid",
	total_yearly_prices: "Total yearly prices",
	totall: "Total",
	totals: "Totals",
	transaction_id: "Transaction id",
	treatment: "for treatment",
	continue_treatment: "To continue treatment",
	ttt: "Ttt",
	tuesday: "Tuesday",
	two_months_before: "Previous 2 months",
	type: "Type",
	unable_to_delete:
		"Unable to delete, it is in use or there is only one record in file",
	undo: "Undo",
	unified: "Unified",
	unit: "Unit",
	price_cancellation: "Price for cancellation",
	unit_cancellation: "Unit for cancellation",
	unsubscribe_message:
		"This email is sent to you , if you no longer want to recieve emails from us feel free to unsubscribe here",
	until_date: "Up to (incl.)",
	update: "Update",
	"update-": "updating ",
	update_calendar: "Update the calendar",
	update_followup: "Update followup",
	update_successfuly: "Updated successfuly",
	update_time: "Update time",
	update_test: "Update questionnaire",
	duplicate_test: "Duplicate questionnaire",
	test_duplicated: "Questionnaire duplicated",
	updated: "Updated",
	updated_at: "Updated at",
	upload: "Upload",
	upload_aborted: "Upload Aborted",
	upload_failed: "Upload Failed",
	download_failed: "Download failed",
	upload_file: "Upload File",
	upload_image: "Attach an image",
	upload_successful: "Image uploaded  successfully",
	no_file_selected: "No file was selected",
	uploading_new_photo_will_replace_the_current_one:
		"Uploading new photo will replace the current one",
	upremarks: "Upremarks",
	url: "Url",
	use_as_template: "Use as template",
	use_few_softwares: "Do you use a use_a combination of applications",
	use_software: "Do you use a software/application to run your cliniq?",
	user: "User",
	user_configuration: "User configuration",
	user_credit_card_has_expired:
		"The credit card of one of your users has expired",
	userdetails: "User details",
	userdetails_website: "User details for your web page",
	user_id: "user",
	user_invoice: "User invoice",
	user_invoice_short: "user invoiceshort",
	user_name: "User Name",
	"user_name/email_or_password_incorrect":
		"User name/email or password are incorrect",
	user_programs: "My program",
	user_settings_were_updated_successfully:
		"User settings were updated successfully",
	user_title: "User title",
	username: "User name",
	users: "Users",
	users_list: "Users list",
	vacation: "Vacation",
	valid_from: "Valid from",
	new_valid_from: "New valid from date",
	valid_to: "Valid to",
	validation_error: "Validation error",
	value: "Value",
	this_value: " this item",
	value1_date: "Value_date",
	value_date: "Value date",
	vat: "VAT",
	expense_vat: "VAT",
	vat_for_payment: "Vat for payment",
	vat_percent: "Vat percent",
	vat_number: "Vat number",
	vat_deductible: "VAT deductible",
	vat_report: "Vat report",
	verify_patient: "Verify patient",
	version: "Version",
	versions: "Versions",
	medilogs: "Versions",
	view_all: "View all",
	views: "Views",
	waiting_for_activation:
		"In these moments an email is sent to you with your activation code . <br>  Simply visit your email box, click on the activation link and confirm that you are the one who enrolled. <br>  By doing that our system is open to full use.<br>  We congratulate you on your choice of my-cliniq as your clinic management platform and do everything so that the system will work to your full satisfaction .",
	waiting_for_patient_activation:
		"In these moments an email is sent to you with your activation code . <br>  Simply visit your email box, click on the activation link and confirm that you are the one who enrolled. <br>  By doing that the chat system will be open for you.",
	want_to_get_in_touch:
		"Want to get in touch with me? Fill out the form below to send me a message and I will try to get back to you within 24 hours!",
	wants_to_reset_the_password: "want to reset the chat password.",
	created_successfully: "Created successfully",
	updated_successfully: "Updated successfully",
	"we_are_sorry,_but_we_cant_find_the_userdetails":
		"We are sorry, but we can not find the user details",
	web_page: "website",
	web_site: "Web site",
	wednesday: "Wednesday",
	wednsday: "Wednsday",
	week: "Week",
	weekly: "Weekly",
	weight: "Weight",
	weight_in_grams: "Weight in grams",
	welcome: "Welcome",
	welcome_back: "Welcome back",
	welcome_to: "Welcome to ",
	whatsapp: "Whatsapp",
	when: "When",
	which_softwares: "Which?",
	who_is_registere_with_the_email: "and email -  ",
	whole_day: "Whole day",
	whoops_no: "Whoops no",
	why: "Why",
	widowed: "Widowed",
	with: "with",
	with_how: "with who",
	without_personal_memorandum: "without Personal memorandum",
	with_personal_memorandum: "Personal memorandum",
	with_description: "Medical Record",
	only_personal_memorandum: "only Personal memorandum",
	with_who: "With who",
	withholding_tax: "Withholding tax",
	is_withholding_tax: "Withholding tax",
	withholding_tax_percent: "Withholding tax percent",
	without: "without",
	withoutVat: "Vat not included",
	without_prodvider: "Without prodvider",
	without_type: "Without Type",
	withoutvat: "Without vat",
	withvat: "With vat",
	wrong_file_format: "Wrong file format",
	wrong_file_dimensions:
		"The dimensions of the image file are wrong. resize or consult support",
	year: "Year",
	year_of_birth: "Year of birth",
	yes: "Yes",
	yes_continu: "Yes continue",
	you_are_here: "You are here",
	you_are_invited: "You are invited",
	you_are_invited_to_a_chat: "You are invited a chat with",
	you_are_not_authorized:
		"you_are_not_authorized to continue, please reconnect",
	you_are_now_being_redirected: "You are now being redirected.",
	you_can_cancel_this_approval:
		"** In order to cancel your approval please let me know that on our meeting an I shall remove it from your records.",
	you_can_see_full_details_of_your_order_in_your_page:
		"You can see full details of your order in your page",
	you_cannot_edit_other_users_profiles: "You cannot edit other users profiles",
	you_cannot_edit_this_content: "You cannot edit this content",
	you_dont_have_followups_for_today: "You dont have followups for today",
	you_have_a_new_message_waiting_for_you:
		"You have a new message waiting for you",
	you_have_meeting: "you have a meeting tomorrow",
	you_have_overlaping_followupseries: "you have overlaping meetings",
	"you_have_overlaping_followupseries:": "You have overlaping followups:",
	you_have_successfully_logged_out: "You have successfully logged Out",
	you_have_successfully_unsubscribed:
		"You have_ successfully unsubscribed from our mailing list",
	you_have_to_activate_your_account_before_you_can_login:
		"you must activate your account before login",
	you_must_check: "You must check the email address that you want to email to",
	you_must_renew_your_payment: "you must renew your payment",
	you_registered_at: "you registered at",
	you_save: "חשחת",
	your_account: "Your account",
	your_account_was_activated_successfully:
		"your account was successfully activated",
	your_account_was_cancelled: "Your account was cancelled",
	your_auto_renew_billing:
		"Your credit card was billed for the subscription fee of -My-Cliniq",
	your_credit_card_has_expired: "Your credit card has expired",
	your_credit_card_no: "Your credit card, No. ",
	your_email: "Your email",
	"your_message_was_sent_and we_shall_contact_you_back_shortly":
		"Your message was sent and we shall contact you back shortly",
	your_monthly_payment: "Your monthly payment ",
	your_outstanding_payment: "your outstanding payment is",
	you_paid: "you paid",
	your_page: "Your page",
	your_partner_is: "Your partner is",
	your_password_has_been_reset_successfully:
		"Your password has been reset successfully",
	your_patient_contacts: "Your patient contacts",
	your_patient_memos: "Your patient memos",
	your_patient_statuses: "Your patient statuses",
	your_subscription_is_free:
		"your subscription of My-Cliniq will be free for one month  .",
	zip: "ZIP",
	bold: "Bold",
	italic: "iItalic",
	underline: "Underline",
	removeformat: "Remove format",
	bgColor: "background Color",
	rtl: "Rtl",
	ltr: "Ltr",
	justify: "Justify",
	justifyRight: "Justify Right",
	justifyCenter: "Justify Center",
	justifyLeft: "Justify Left",
	Rtl: "Rtl",
	wrong_vacation_time: "wrong vacation time, change end time ",
	jan: "January",
	feb: "February",
	mar: "March",
	apr: "April",
	may: "May",
	jun: "June",
	jul: "July",
	aug: "August",
	sep: "September",
	oct: "October",
	nov: "November",
	dec: "December",
	sent: "Sent",
	read: "Read",
	bounced: "Bounced",
	Bounce: "Bounced",
	bounce: "Bounced",
	scheduled_reports: "Scheduled reports",
	include_documents_in_scheduled_rep: "Include documents in scheduled reports",
	report_name: "Report name",
	send_freq: "Report period",
	date_in_month: "Date in month",
	in_month: "In month of",
	"3": "3",
	"5": "5",
	"7": "7",
	attached_reports: "Attached reports",
	the_attached_reports_are: "The attached reports are",
	marked_holidays:
		"The selected holidays will generate a vacation in the calendar",
	holiday_group: "Holiday type",
	national: "national",
	jewish: "Jewish",
	muslim: "Muslim",
	christian_catholic_protestant: "Christian-catolic/protestant",
	christian_orthodox: "Christian orthodox",
	druze: "Druze",
	cherkess: "Cherkess",
	you_seem_to_be_disconnected:
		"You seem to be disconnected, please reconnect to resume activity.",
	total_invoice_total_without_vat: "Total invoice total without vat",
	total_invoice_vat_required: "Total invoice vat required",
	total_invoice_vat: "Total invoice vat",
	total_expense_vat_permanent: "Total expense vat permanent",
	total_expense_vat_not_permanent: "Total expense vat not permanent",
	tax_for_payment: "Tax for payment",
	tax_percent_downpayment: "Downpayment percent",
	total_invoice_tax_downpayment: "Downpayment",
	there_is_credit: "Credit from last payment",
	there_is_debt: "Debt from last payment",
	to_proceed_with_diff:
		"There is a difference beetween the sum and the invoice total",
	to_proceed: "Do you wish to proceed?",
	new_advance: "New advance",
	new_debt: "New debt",

	please_include_the_followup_in_the_calculation:
		"It is not allowed to leave credit without including all followups in payment",
	no_debt_on_invoice: "It is not allowed to leave debt while taking a payment",
	border_color: "Border color",
	template_id: "Template",
	lang: "Language",

	you_were_invited: "You were invited to register to My-Cliniq by ",
	you_were_invited_to_join:
		"You were invited to join a partnership in My-Cliniq by ",
	you_have_to_register:
		"Please insert your mobile phone, your desired user name and password and follow the instuctions",
	add_new_role: "Add new role",
	confirm_duplicate_name: "Confirm duplcate name",
	user_added: "A user has been added",
	role_added: "A role has been added",
	name_already_exists: "This name exists already",
	this_role_can_not_be_deleted:
		"The role can not be deleted it is related to a user",
	sub_user_register: "Sub user Regiter",
	sub_users: "Sub users",
	sub_users_count: "Active sub users",
	admin_sub_users: "Users dashboard",
	sub_users_dashboard: "Users dashboard",
	sub_users_therpaists: "Therpaists",
	filter_sub_users: "Filter users",
	clinic_roles: "Roles",
	perm: "Permission",
	perms: "Permissions",
	perms_role: "Role",
	role_perms: "Role permissions",
	user_perms: "User permissions",
	users_assoc: "Therapists list",
	assoc_users_for: "Therapists list for",
	is_assoc: "Therapist",
	assoc_sub_user_id: "Therapist",
	assoc: "Therapists list",
	assoc_inquiry: "referred to",
	first_assoc: "First association",
	therapytype: "Therapy type",
	therapytypes: "Therapy types",
	object_to_sub_users: "Theraptis of patients/meetings",
	object_to_sub_users_title: "Theraptis of patients",
	application_link: "A link to a paying application",
	bank_account_text: "Bank account text",
	pricingtype: "Pricing type",
	pricingtypes: "Pricing types",
	price_minus_sub_user_tarif: "After therapist expense",
	sub_user_tarif: "Sub user price",
	sub_user_tarifs: "Sub user prices",
	sub_user_tarif_paid: "Paid to therapist",
	price_sub_user: "Sub user price",

	print_email_prompt: "Print or email document",
	the_system_was_reset: "The system was reset, please login again",
	unsubscribed_message: "Good bye, it will be great to meet again",
	free: "free",
	"3months_free": "1 month trial",
	print_multiple_invoices: "Download selected invoices",
	email_multiple_invoices: "Email selected invoices",
	attached_summary: "The monthly hours are attached",
	multiple_finresources: "multiple copies of documents",
	mixed_list: "medical records",
	mixed_lists: "medical records",
	time_allocation: "Allocation",
	time_allocations: "Allocation in a table",
	time_room_allocation: "Room allocation",
	is_conflicting: "Conflict",
	location: "Room",
	location_id: "Room",
	locations: "Rooms",
	no_location: "No location allocation",
	must_include:
		'Invoice header should include "ע.מ." או "ע.פ." או "ח.פ."+ the number',
	sub_user_group: "distribution list",
	sub_user_groups: "distribution lists",
	sub_user_group_members: "Group members",
	sub_user_group_member: "Group member",
	create_potential_user: "Create a potential user",
	potential_user: "Potential user",
	potential_users: "Potential users",
	for_an_answer: "Do no reply. To reply use your contact email or whatsapp",
	for_an_answer1: "** To reply",
	for_an_answer2: "By whatsapp",
	for_an_answer3: "or email",
	this_is_an_automated_message: "this is an automated message",
	you_have_a_meeting: "you have a meeting",
	tomorrow: "tomorrow",
	at: "at",
	error_in_time_range: "Error in time range",
	time_is_taken: "The time is taken",
	room_is_taken: "The room is taken",
	bkmvdata: "To generate bkmvdata.txt",
	ini: "To generate  ini.txt",
	sub_user: "Therapist / Sub user",
	all_fus: "All meetings",
	non_patients: "No patients",
	tasks: "Tasks & reminders",
	task: "Task/ Reminder",
	conflict_reason:
		"[conflict_reason_name] already has a meeting on day [date] at [time]  with [assocName] ([series_repeat])",
	to1: "",
	a_one_time: "a meeting on  ",
	a_series: "a series of meetings on ",
	credit_invoice_preview: "Credit invoice preview",
	credit_justinvoice_preview: "Credit invoice_preview",
	credit_receipt_preview: "Credit recei[t preview",
	credit_admininvoice_preview: "Credit invoice preview",
	credit_justinvoice: "Credit invoice",
	credit_receipt: "Credit invoice",
	credit_admininvoice: "Credit invoice",
	multiuser: "Users management",
	no_group_assoc: "It is forbiidden to associate a group to a patient",
	no_allocation: "no room allocation",
	max_1_group_assoc: "only 1 group can be associated to a meeting",
	urgent: "Urgent",
	sms_notify: "Notify by sms",
	email_notify: "Notify by email",
	set_is_uptodate: "Uptodate",
	close_task: "Close task",
	open_close_chat: "Open Close all chat",
	open_close_all_chats: "Open Close all chat",
	add_comment: "Comment",
	add_task: "Add a task",
	calendar_addFusModal_finFields: "Add financial fields",
	calendar_addFusModal_canOpen: "Add/edit meetings",
	assign_sub_users: "Associate therapist",
	receipts: "Receipts issue",
	document_signing: "sign a document",
	finances_expenses: "expenses",
	finances_income: "income",
	administrative: "administrative",
	financial_documents: "financial documents",
	clinical_admin: "Clinical administration",
	sees_admin_homepage: "Admin home page",
	exit: "Exit",
	time_to_refresh: "The calendar ws updated please refresh the page ",
	inquirer: "inquirer",
	addinquirer: "Add inquirer",
	testquestion: "Question",
	testquestions: "Questions",
	testanswer: "Answer",
	answer: "Answer",
	testanswers: "Answers",
	answers: "Answers",
	mark: "Mark",
	default_answer: "Default answer",
	clinical_home: "Clinical page",
	"home-admin": "management page",
	add_insert_autotext: "Add and insert texts",
	choose_autotext: "choose text",
	choose_test: "Select a questionnaire",
	add_insert_tests: "Add and insert questionnaires",
	tarif: "Tarif",
	tarif_id: "Tarif",
	tarifs: "Tarif",
	to_update_delete: "To Update / Delete ",
	to_delete: "To Delete ",
	send_documents_to_patient: "Enail chosen documents",
	downlaod_documents_to_patient: "Download chosen documents",
	calendar_view: "calendar view",
	list_view: "List view",
	select_change_serie: "Please select the change you need",
	new_inquiery: "New inquiery",
	new_inquiry_without: "New inquiery",
	new_inquiry_with: "New inquiery with Therapist",
	effective_date: "Effective date",
	vacation_all_users: "Vacation for all",
	time_allocation_warning:
		"Warning! this time and room is allocated to another",
	not_in_allocation: "Warning! this time was not allocated by the user",
	referrer: "Referrer",
	authorized_signer: "Authorized signer",
	signer: "Signer",
	authorized_to_sign: "Authorized to sign",
	sub_users1: "People in the institute",
	sign: "Sign and approve",
	occupied: "The room is occupied",
	please_choose_date_column: "You must select date",
	import_warning:
		"If you have already started working and you have inserted patients please beware that there might be doubles because of spelling issues. in case this happens please contact us.",
	followups_sub_user_report: "Followups per user",
	followups_sub_user_rent_report: "Followups and rent per user",
	patients_sub_user_report: "Patients per sub user",
	send_sms: "Send text reminders",
	send_sms_cancel:
		"To cancel the texting reminders option please contact the system administrator",
	sms_approved_cancel:
		"The text messaging is enabled and approved. To cancel this option please contact the system administrator",
	to_sms_terms: "To sign the texting contract",
	to_sms_terms_description:
		"In order to be able to send sms, you have to comply with the sms terms",
	accept_sms_terms: "Accept sms terms",
	serve_for_signing: "Serve for signing",
	needs_to_be_signed: "needs to be signed",
	sub_user_payments: "Users payments",
	unpermitted: "This is not permitted",
	to_pay: "To payment",
	to_pay_instructions: "To pay please fill in the details",
	to_be_paid: "To be paid",
	to_get_paid: "To get paid",
	to_receive: "To get paid",
	the_sms_usage_is_approved: "The sms usage is approved",
	confirm_transfer: "Confirm bank transfer",
	confirm_invoice: "Confirm invoice recieved",
	invoice_number: "Therapist invoice",
	before_day: "it is forbidden to change a series retroactively",
	expense_sum: "Invoice sum",
	expense_date: "Invoice date",
	payment_for_month: "A payment for",
	payment_for: "A payment for",
	amount_of: "on the amount of",
	transferred: "has been transferred to you",
	month_in_future: "The month is in the future, there are no data yet",
	"document-and-quest": "Templates and questionnaires",
	people: "Patients/Contacts",
	patients_sms: "List for sms approval",
	sms_confirmed: "The usage of text messages is confirmed",

	date_of_birth: "Date of birth",
	date_start_therapy: "Start therapy date",
	contact_name1: "Guardian 1 name",
	contact_id1: "Guardian 1 id.",
	contact_relation1: "Guardian 1 relations",
	contact_phone1: "Guardian 1 phone",
	contact_email1: "Guardian  1  email",
	contact_name2: "Guardian 2 name",
	contact_id2: "Guardian 2 id.",
	contact_relation2: "Guardian 2 relations",
	contact_phone2: "Guardian 2 phone",
	contact_email2: "Guardian 2 email",
	contact_name3: "Guardian 3 name",
	contact_id3: "Guardian 3 id.",
	contact_relation3: "Guardian 3 relations",
	contact_phone3: "Guardian 3 phone",
	contact_email3: "Guardian 3 email",
	zones: "Patient file definition",
	apotropus: "Contact/Apotropus",
	apotropuses: "Contacts/Apotropus",
	relation: "Relation",
	no_letter_header_yet: "There is no formal header yet.",
	assign_multi_user: "Assign multi user",
	"there_should be_change": "You must enter the new value of the percent",
	name_on_message: "Name on text message",
	time_of_sms: "Time to send notifiction to clients",
	days_to_sms: "number of days before the meeting to text the reminder",
	time_of_sms_description:
		"the texts will be sent a day before the meeting either at 9 am or 9 pm",
	morning: "Morning",
	evening: "Evening",
	wrong_text: "Wrong text",
	name_should_contain:
		"The name should be 4-11 letters long, English letters dots an hyphens",
	your_balance_for_period: "Your balance is",
	your_balance_at: "Your balance at",
	send_sms_proforma: "Send previous month proforma by sms",
	send_sms_proforma1: "Send link by sms",
	link_will_be_sent: "The link will be sent to ",
	send_wa_proforma: "Send previous month proforma by Whatsapp",
	send_email_proforma: "Send previous month proforma by Email",
	confirm_sending: "Confirm sending",
	resend_all_reports: "Resend all reports",
	no_phone_or_no_permit:
		"The client/patient may not have a phone or has not granted permisssion for texts",
	row: "Row",
	col: "Column",
	above: "Add above",
	below: "below",
	// 'remove': 'שליחה חוזרת של כל הדוחות',
	before: "add right",
	after: "left",
	bg_color: "background color",
	group_name: "Group name",
	faq: "FAQ",
	graphs: "graphs",
	year_from: "From year",
	year_to: "until",
	freq: "Frequency",
	quarterly: "Quarterly",
	yearly: "Yearly",
	income_price: "Income/Prices",
	price_total: "Total meetings cost",
	columns: "Legend",
	state: "State",
	states: "States",
	revoke_in_days: "Show in X days",
	repeat_every_days: "show every X day",
	meeting_was_deleted: "Please state the reason for deleting a past meeting",
	hours: "Hours",
	meetings: "meetings",
	new_patients: "New patients",
	inquirers: "New inquirers",
	patients_with_meeting: "Patients with meeting",
	show_all_open: "Show all open tasks",
	show_only_today: "Show only tasks of today",
	default_font_for_documents: "Default font for documents",
	miriamlibre: "Miriam",
	davidlibre: "David",
	frankruhllibre: "Frankruehl",
	your_one_time_password_is: "Your one time password is",
	show_numbers_not_names: "Show numbers instead of names",
	show_emails: "Show emails",
	show_by_created_at: "Order by insert date",
	by_created_at: "by insert date",
	by_expense_date: "Order by expense date",
	show_by_expense_date: "by expense date",
	show_only_followups: "Show only follow ups",
	show_finresources_and_followups: "Show documents and Follow ups",
	show_only_unpaid_followups: "Show only unpaid Follow ups",
	no_permit_to_notify:
		"There is no permission to send this patient,notifications",
	no_valid_phone: "This patient does not have a valid phone",
	no_followups_or_debt: "This patient does not have meetings or debt",
	referers: "Referrers",
	referer: "Referrer",
	refererpatients: "Referrers",
	refererpatient: "Referrer",
	email_report_to_patient: "Email report to patient",
	resend_invite: "Resend invitation to register",
	"pdf-view": "Preview",
	check_faq: "Please search our FAQs for the help you are looking for",
	patientfields: "Extra fields for the patient file",
	patient_details_fields: "fields on patient form",
	filter_locations: "Filter location",
	filter: "Filter",
	phone_taken: "The phone number  exists",
	inherited_value: "Value on role permission",
	add_referer: "Add referrer",
	select_fields: "Select relevant fields to patient file",
	contactway_already_exists: "The phone/email/address exists",
	insert_patient_form: "Insert new inquirer",
	reset_filters: "Reset filters",
	ref: "reference",
	birthday_today: "Today is the birthday of ",
	was_added: "Was added",
	updated_to: "Was updated to",
	was_deleted: "Was deleted",
	assoc_is: "Associated with",
	user_payments: "User payments",
	you_have_task_waiting: "You have a new task/referal waiting for you",
	show_task_after_login: "Click to show task after login",
	payment_request: "Payment announcement",
	insert_credit_card: "Please insert your credit card details ",
	payment_request_text_new: "Your free trial period has come to an end. ",
	payment_request_text_renew:
		"Your credit card was not charged thie month. Please update your credit card data  ",
	if_paid_ignore: "If your already fixed that issue, please ignore",
	inquiry_status: "Inquiry status",
	inquiry_statuses: "Inquiry status",
	unassigned_to_assoc: "The therapist was referred to the patient",
	assigned_to_assoc: "The therapist was referred to the patient",
	meeting_was_set: "A meeting was set",
	assign_recipient: "Subscribed",
	assign_to_assoc: "Assign to a therapist",
	contactway_search: "Search by phone or email",
	rent_report: "Rented rooms report",
	locations_report: "Taken rooms",
	rent: "Taken locations",
	dblogs: "Old log",
	faqs: "Faqs",
	activity: "Login logs",
	patient_status: "Patient status",
	active_assoc: "Active connection",
	reached_14: "The patient is on the 14th meeting today",
	delete_forbidden: "There is an ivoice no delete",
	supervisor_notes: "Supervisor notes",
	choose_form: "Choose form",
	add_insert_forms: "Insert forms",
	edit_form: "Edit form",
	form_name: "Form name",
	form_submit: "Submit form",
	correct_answer: "Correct answer",
	show_is_assoc: "Show choice with or without therapist",
	with_assoc: "With therapist",
	without_assoc: "Without therapist",
	short_name: "short_name",
	for_patient: "שאלון למילוי מטופל",
	vvalue: "Answer",
	value_not_in_range: "value_not_in_range",
	error_in_range: "error_in_range",
	add_option: "Add an option",
	add_values: "Add values",
	at_least_2: "At least 2 options",
	show_only_conflicting: "show only conflicting",
	field_type: "Answer type",
	text: "Text",
	"opts-select": "Select",
	"opts-checkbox": "Checkbox",
	"opts-btn-group": "Buttons",
	range_min: "minimal value",
	range_max: "maximal value",
	options: "Options",
	testanswers_fill: "Test preview",
	preview_filled_form: "Form preview",
	backward: "Move backward",
	forward: "move forward",
	position: "Position",
	position_start: "Right",
	position_center: "center",
	position_end: "Left",
	countable: "Countable",
	is_private: "Private",
	Sunday: "Sunday",
	Monday: "Monday",
	Tuesday: "Tuesday",
	Wednesday: "Wednesday",
	Thursday: "Thursday",
	Friday: "Friday",
	Saturday: "Saturday",
	irrelevant: "Irrelevant",
	row_can_have_either_1_or_2_items: "A row can have either 1 or 2 items",
	if_row_has_2_they_can_only_be_right_and_left:
		"If a row_has_2_they_can_only_be_right_and_left",
	if_center_only_one_item: "In the center there can only be one item",
	"show-item": "Show",
	get_2fa_key: "Get Authenticator key",
	the_key: "Authenticator key",
	is_google2fa: "Use Authenticator",
	headerText: "header text",
	design_your_document: "Design your document",
	area: "Area",
	count_is_private_yes: "Total private",
	count_is_private_no: "total non private",
	not_found: "Not found",
	choose_or_insert:
		"Select an existing patient/contact or insert by clicking on the + icon",
	confident: "Confident",
	create_takbull_account: "Create an account in Takbull",
	create_grow_account: "Create an account in Grow",
	ita_get_auth: "Get Its Authorization",
	ita_refresh_token: "Refresh Ita tokens",
	enable_israel_invoice: "Enable israel invoice",
	no_refresh_token_or_Refresh_token_is_outdated:
		"No refresh token or Refresh token is outdated",
	no_access_token_found: "No access token found",
	affiliate_link: "Copy Affiliate link",
	affiliate_header: "Invite friends to My-Cliniq",
	affiliated_users: "My affiliates",
	to_meeting_request: "to Meeting request with",
	meeting_requests: "Meeting requests",
	select_partner: "Select a partner",
	already_in_partnership: "Partner exists",
	show_motions: "Referal followup",
	to_request_meeting_request: "A Request for a meeting",
	meeting_request: "Meeting request",
	add_meeting_request: "Add Meeting request",
	meeting_request_with_no_calendar: "Meeting request without time",
	meeting_request_with_calendar: "Meeting request with time",
	meeting_request_was_sent: "Meeting request was sent",
	meeting_details: " Your Meeting will be on ",
  leave_details: "Leave your details here and we'll get back to you soon",
	your_meeting_details: " Your request for a meeting ",
	you_have_a_meeting_request_on: "You have a meeting request on",
	you_have_a_contact_request_on: "You have a contact request",
	the_contact_request_will_appear: "The request will appear on your task list",
	external_link: "Get externallink",
	copy_external_link: "copy webpage and external link",
	copy_external_calendar_link: "To calendar without login",
	copy_link: " Copy link",
	copy: " Copy",
	copycopy: " Copy",
	phone_search: "Search by phone",
	confirm_meeting: "Confirm the meeting",
	isNew: "New?",
	individual: "Individual",
	this_is_group: "A group can not be unified",
	same_patient: "this is the same patient",
	wellcome: " Wellcome to My-Cliniq",
	i_want_instruction: "I want an instruction, please.",
	received_instruction_request:
		"Your instruction request was received. Our instructor will be in touch with you shortly",
	do_you_want_instruction:
		"It is advised to take the instruction time (it is free of charge). let us know if you want by clicking the button below",
	do_you_want_instruction_multi:
		"It is advised to take the instruction time (it is free of charge). let us know if you want by clicking the button below",
	instruction_suggest: "Instruction offer",
	patient_source: "The patient from which the data will be copied",
	patient_tarrget: "The patient that will remain",
	unify: "Unify",
	patient_unification: "Patients unification",
	message_sent_to_instructor: "The message was sent to the instructor",
	bn_number: "business number",
	first_time_meeting_request: "New-online order",
	return_meeting_request: "returning-online order",
	name_exists_differnet_phone: "The name exists but with a differnet phone",
	name_exists_phone_belongs_to_another_name:
		"The name_exists but the phone belongs to another name",
	name_does_not_exist_phone_exists: "The name does not exist but phone exists",
	switch_locations: "switch locations",
	"no-locations_at_this_time": "There are no locations at this exact time",
	open_test_manage: "Questionnairs manager",
	all_answers_filled_saved: "All answers filled and saved",
	"2FA1": "In order to enable 2fa",
	"2FA2": "Download an authenticating app.",
	"2FA3": 'Open the app and start a new connection call it "my-cliniq"',
	"2FA4":
		'the app wiil request a QR-code scan, Open My-Cliniq , on the upper menu on the left select "enable 2fa" scan the QR code that will appear ',
	"2FA5": "4. Save the new onnection on the app.",
	"2FA6":
		"From now on, instead of waiting for a text messag, open the app andinsert the number that will appear ther to the My-Cliniq requested code.",
	"2FA7":
		'Click "yes" on the button below and from now on this will replace the usage of text messages as 2FA.',
	for_first_time_meeting: "To select a time for a first time meeting",
	for_return_meeting: "To select a time for a return meeting",
	is_this_first_time: "Is this your first time at?",
	deactivate_meeting_request: "Reject meeting request",
	tests_manage: "Questionnairs",
	close_task_with_meeting_request:
		"Warning! closing the task will reject the meeting request",
	your_meeting_request_for: "Yor meeting request with",
	"to_meeting-request_no_calendar":
		"For a meeting request with no specific time",
	"to_meeting-request_no_calendar_with":
		"For a meeting request with no specific time with",
	confirmed: "is confirmed",
	denied: "is denied",
	your_request_was_sent: "Your meeting request has been sent",
	to_payment_patient: "To pay this bill",
	with_credit_card: "with credit card",
	with_bit: "with Bit",
	meetingtypes_and_time_allocation_shoud_be_defined:
		"to get the link you must set time allocation anf meeting types for online booking",
	thank_you_for_your_referral: "Thank you for your referral of",
	your_next_payment_is_free: "you next month usage will be free of charge",
	thank_you_from_my_cliniq: "Thank you from My Cliniq",
	enter_to_see_it_on_calendar:
		"The request will appear in your calendar or on your task list",
	select_meeting_type: "To select the requested meeting type",

	choose_one: "Please select",
	choose_one_email: "select one email",
	choose_one_phone: "select one phone number",
	choose_one_phone_and_one_email: "select  one email and one phone number",
	email_is_missing: "There is no email",
	phone_is_missing: "There is no phone",
	invitation_sent_successfully: "The invitation has been sent",
	invitation_not_sent: "Failed to send",

	too_many_tries: "Too many tries",
	wrong_otp: "wrong code",
	login_failed: "Login failed",
	register_failed: "Registration failed",
	resend_otp: "Resend code",
	send_invite: "Patient invitation",
	send_invite_to_fill: "Patient invitationto fill in details",
	send_patient_form: "send details form",
	to_patient_fill_form: "to fill in the details",
  patient_filled_test:'Patient filled test',
  patient_filled_form:'Patient filled details',
	patient_fill_form: "fill in the details",
	update_from_patient_form: "Update patient from form",
	updated_from_patient_form: "Patient details updated",
	choice: "Choose",

	selected_email: "The selected email",
	selected_phone: "The selected phone",
	sent_invite: "The invitation is sent again now",
	invitation_cancelled: "invitation cancelled",

	occupation: "Profession",
	full_address: "address",
	languages: "Languages",
	areas_of_expertise: "Expertise",
	website_link: "Website",
	"an_invitation_to_register_to_my-cliniq": "an invitation to use My-Cliniq",
	you_are_invited_by: "You are invited by",
	" to_register_to_my_cliniq": " to enter My-Cliniq",
	"after_registration_you_will_be_able_to_access_your_payments,":
		" My-Cliniq will enable you an access to your payments,",
	"see_when_is_your_next_meeting ": "see when is your next meeting ",
	"and_more...": "and more...",
	"please_click_on_the_link ": "Please click on the link ",
	to_pay_please_click_on_the_link: "To pay please click on the link  ",
	to_pay_by_credit: "To pay with credit card",
	more_instructions_in_the_process:
		"additonal instructions will be given in the registration process.",
	"with_payment-details": "Meeting detials",
	"without_payment-details": " without Meeting detials",
	search_by_age: "Search by age",
	digits_only: "Only digits are allowed",
	patient_sign_required: "patient signature is required",
	required: "Required answer",
	user_sign_required: "User signature is required",
	calculated: "Calculated",
	not_same_values: "The marks should be different",
	your_code_is: "your identifying number is",
	invitation_to_login: "an invitation to log into My-Cliniq  ",
	message_is_waiting:
		"you have a message qaiting for you on your private area of  ",
	in_my_cliniq: "in My-Cliniq.  ",
	to_enter_click_here: "Please click here to enter the secure page. ",
	patient_is_registered:
		'The patient is on the system, by clickong "send" a link to login will be sent',
	patient_should_register:
		'The patient has already been invited to register, by clickong "send" a link to register will be sent',
	phone_and_email_are_required: "A phone and an email are both required",
	phone_or_email_are_required: "Either a phone or an email are required",
	extra_freeform_details: "Extra details",
	contactway_list: "Emails list for export",
	test_sent: "Questionnaire has been sent",
	test_invite: "an invitation to fill in a Questionnaire ",
	to_the_test: "to the Questionnaire",
	to_fill_in_test: "To fill in a questionnaire/form ",
	filled_test_sent: "Questionnaire has been sent",
	filled_test_already_sent: "Questionnaire has been already sent ",
	group_vacation_forbidden: "a Vacation for a group is forbidden",
	please_fill: "Please fill in the attached questionnaire",
	please_fill_in_the_link: "Please fill in the questionnaire in the link",
	please_fill_in_the_links: "Please fill in the questionnaires in the links",
	waiting_tests: "unfilled questionnaires",
	insert_future_meetings: "Insert future meetings",
	without_invoice: "Without invoice",
	test_should_be_signed: "The questionnaire should be signed",
	patient_signature: "Client signature",
	user_signature: "Witness signature",
	to_set_the_appointment: "Set the appointment",
	to_send_the_request: "Send the request",
	confirmation_sent: "a confirmation text message was sent to your phone",
	see_you_on: "See you on ",
	accepted: "has been accepted",
	accept: "has been accept",
	all_vat_numbers: "All vat numbers",
	to_download: "Do you want to download the document",
	no_meetingtypes_available: "There are no meetings for this request",
	is_selfinvoice: "Has a permit for self invoice",
	selfinvoice: "Self invoice",
	first_selfinvoice_num: "First number of the self invoices series",
	name_should_be_inserted: "Please enter your name",
	phone_should_be_inserted: "Please enter your phone",
	email_should_be_inserted: "Please enter your Email",
	the_followup_has_description:
		"?This meeting has content, Should it be deleted?",
	is_finresource_connected_only: "Open proformas and invoices",
	open_proformas: "Open proformas",
	record_is_locked: "The file is locked. another user is editing it.",
	partner: "Partner",
	partners: "Partners",
	invite_partner: "Invite partners",
	please_enter_partner_email: "Please enter the email address of your partner.",
	declined: "Declined",
	directed: "Directed",
	show_letters: "Show letters",
	show_documents: "Show documents",
	show_therapist: "Therapist on details",
	show_email_in_pdf: "Show email in Pdf",
	ok: "OK",
	validating: "Is being checked",
	taken: "The number/email exists",
	former_debt: "Former debt Former debt",
	add_former_debt: "add ",
	former_debt_text: "You can add here any former debt of the client",
	the_request_will_be_verified:
		"The request will be checked and a final confirmation will be sent to you",
	preferences: "Preferences",
	signature_logo: "Signature image",
	email_selected: "Email selected documents",
	download_selected: "Download selected ducoments",
	meeting_times: "time slots suggestion",
	overdue: "Overdue",
	checking: "Checking",
	set_meeting: "Set a meeting",
	accept_patient: "Accept patient",
	accept_patient_terms: "I Accept the terms of my partner",
	make_not_active: "Make not active",
	no_saturday: "Not to show saturday on calendar",
	enable_past_followups_on_followup: "Show past followups on home meeting",
	enable_group_attends_on_followup:
		"Show group members medical record on home meeting",
	enable_less_fus: "calendar only from last year",
	id_number_required: "An id number is required",
	finResource_person_list: "Financial documents",
	computerized_document: "Computerized document",
	future_meetings: "Future meetings",
	repayment_by_token: "Repayment by token",
	MaxNumberOfPayments: "Maximum number of payment for credit card transactions",
	assoc_date: "Date of association",
	first_meeting_date: "First meeting date",
	last_meeting_date: "Last meeting date",
	active_users: "Active users",
	active_patients: "Active patients",
	date_format: "Date format",
	invalid_date: "invalid date",
	recommended: "Recommended",
	security: "Security",
	self_coordination: "Self coordination",
	maximize: "Maximize",
	minimize: "Minimize",
	show_future_meetings: "Show future meetings",
	show_search_by_phone: "Show search by phone",
	test_id: "Questionnaire",
	sub_test_id: "Sub questionnaire",
	not_allowed:
		"If you would like to import patient's data, please contact support ",
	decline_instructions: "Dont show again",
	leave_affiliate_name_empty: "You may leave this empty and get 1 month free",
	contacts_meetings: "Meetings with contacts",
	count: "Count",
	expenses_by_created_at_default: "Expenses by created date as default",
	expenses_by_former_month: "Expenses calculated as former month",
	debts_payor_report: "Payor debt",
	install_app: "» Install application",
	taxesReportMode: "Taxes report mode",
	taxes_report_mode: "Taxes report mode",
	taxes_income_tax: "Taxes income tax",
	taxes_vat: "Taxes vat",
	confirm_partnership: "Joining partnership confirmation",
	i_confirm_partnership: "I hereby confirm the partnership",
	report_selection: "Reprts selection",
	select_a_patient: "Please select a patient from the list",
	until_last_month: "Until last month",
	until_now: "Until now",
	add_payor_to_followup: "Payor",
	multi_user: "Multi user",
	subscription_type: "Subscription type",
	entry_selection: "Entry selection",
	list_of_owners: "List of other owners",
	proceed_with_main_uesr: "Proceed with mainuesr",
	mirror_user_please_login_as:
		"You have a message, please login as the user of",
	remembered: "» Remembered?",
	is_locked: "locked",
	email_proforma: "Email proforma",
	email_invoice: "Email invoice",
	send_proforma: "send proforma",
	"please_pay-ahead": "Please pay in advance",
	no_report_was_chosen: "No report was chosen",
	budget_sum: "Budget",
	budget_for_payors: "budget for payor",
	budget_percent_for_alert: "Budget percent for alert",
	budget_date: "Budget date",
	budget_usage: "Budget usage",
	budget_usage_percent: "Budget usage percent",
	payor_budget_alert_message1: "The budget of",
	payor_budget_alert_message2: "Has passed its Budget percent for alert",
	payor_budget_alert: "Budget percent for alert",
	account_for_deposit: "to make a deposit",
	last_365_days: "Last year",
	last_30_days: "30 days",
	last_90_days: "last 3 months",
	has_closing_tags: "invalid character",
	flip: "Belongs to",
	name_on_invoice: "Name on invoice",
	search_by_tags_add_meeting: "Search by tags on insert a meeeting",
	select_report_fields: "Select reports columns",
	personalize: "Personalize report",
	invoices_report_by_opts: "Report options",
	report_defs: "Report parameters",
	tests_in_group: "Questionnairs in package",
	testgroups: "Package of Questionnairs",
	add_to_group: "Add to package",
	invalid_text: "Invalid text inserted, please contact support",
	version_change:
		"There is a new version, try to refresh the page. I case this happens again please contact support",
	guest_sched_hours_to_add: "self coordination start time",
	patient_id_number: "Id number",
	contact_id: "Contact",
	expensetype_id: "Expense type",
	blank_value: " ",
	after_today: "Future date is forbidden",
	token_received: "The token is good",
	token_was_not_received: "The token is not good",
	wa_app_installed: "Whatsapp app is installed",
	ita_no_signup:
		"You have asked to join Israel-invoice but did not sign up to the digital services of the ITA",
	ita_no_login: "You have asked to join Israel-invoice but did not sign in yet",
	ita_refresh: "To refresh the tokens",
	ita_sign_up: "Ita sign up",
	shekel: "Shekels",
	get_israel_invoice: "Get invoice approval from ITS",
	israel_invoice: "Invoice allocation number",
	automation: "Automation",
	intern: "Intern",
	download_documents: "Download documents to computer",
	"COATED TABLETS": "Tablets",
	"COATED TABLETS1": "Tablet",
	"FILM COATED TABLETS": "Tablets",
	"FILM COATED TABLETS1": "Tablet",
	"MODIFIED RELEASE CAPSULES": "Capsules",
	"MODIFIED RELEASE CAPSULES1": "Capsule",
	CAPSULES: "Capsules",
	CAPSULES1: "Capsule",
	"OROMUCOSAL SOLUTION": "Solution",
	SOLUTION: "Solution",
	"SOLUTION (ORAL)": "Solution (oral)",
	"SOLUTION FOR INJECTION": "Solution (for injection",
	"SOLUTION FOR INJECTION / INFUSION": "Solution/Infusion",
	SYRUP: "Syrup",
	"OROMUCOSAL SOLUTION1": "Solution",
	SOLUTION1: "Solution",
	"SOLUTION (ORAL)1": "Solution (oral)",
	"SOLUTION FOR INJECTION1": "Solution (for injection",
	"SOLUTION FOR INJECTION / INFUSION1": "Solution/Infusion",
	SYRUP1: "Syrup",
	TABLETS: "Tablets",
	TABLETS1: "Tablet",
	"TABLETS CONTROLLED RELEASE": "Tablets",
	"TABLETS CONTROLLED RELEASE1": "Tablet",
	"TABLETS EXTENDED RELEASE": "Tablets",
	"TABLETS EXTENDED RELEASE1": "Tablet",
	"TABLETS PROLONGED RELEASE": "Tablets",
	"TABLETS PROLONGED RELEASE1": "Tablet",
	times: "times",
	time_1: "once",
	patient_in_my_clinic: "A patient in my clinic",
	diagnosed_with: "diagnosed with",
	pagination_in_big_tables: "Pagination in big tables",
	MG: "MG",
	"MG/ML": "MG/ML",
	adhd_note: "Diagnosed as ADHD",
	treated_with: "Treated with",
	parents_married: "Married",
	parents_not_married: "Not married",
	father_widowed: "Father widowed",
	mother_widowed: "Mother widowed",
	parents_divorced: "Divorced",
	short_meetings_calendar: "Short meetings calendar",
	notifications_upon_login: "Open Tasks & notifications upon login",
	public_tests: "Public questionairs",
	products: "Products",
	product: "Product",
	use_this: "Use this",
	sub_title: "Sub title",
	external_id: "External Id",
	product_person: "Buyer",
	product_persons: "Buyers",
	select_tag: "Select tag",
	default_medical_letter: "Medical letter template",
	got_instruction: "Got instruction",
	instractor_name: "Instructor name",
	coupon: "Code Coupon",
	coupon_price: "Coupon price",
	coupon_date_limit: "Date limit",
	not_valid_coupon: "This coupon is not valid",
	no_hotmail: "Please dont use a hotmail email address",
	"add-draft": "Add draft",
	walkthrough: "Walkthrough",
	next_step: "To continue",
	next_time: "Thanks, not now",
	see_u_next_time: "Sad to see you leave",
	bye: "Bye",
	scale_id: "Scale id",
	scales: "Scales",
	testscales: "Scales",
  new_scale_name: "New sub scale name",
	calculation_method: "Calculation method",
	avg_sd: "Avg/sd",
	interpretation: "Interpretation",
	max: "Max",
	population: "Population",
	average: "Average",
	special_notification:
		'שלום,<br/>עקב תקלה בחישוב המע"מ, נאלצנו לזכות את המנוי שלך ולחייב אותו מחדש.<br/> החל מהחודש הבא (פברואר), אחוז המע"מ החדש ישתקף במחיר המנוי הכולל.<br/><br/>בברכה,<br/>צוות My-Cliniq\n',
	thank_you_for_your_attention: "Thank you for your attention",
	i_saw_notification: "I saw the notification",

	walkthrough_step_o_line_1: "Wellcom",
	walkthrough_step_o_line_2: "לרשותך מערכת היכרות ראשונית והתחלת עבודה מהירה",
	walkthrough_step_o_line_3: "aaaaaa",
	walkthrough_step_o_line_4: "aaaaaa",
	walkthrough_step_o_line_5: "aaaaaa",
	walkthrough_step_1_line_1: "מה חשוב לך?",
	walkthrough_step_1_line_2: "התחלת עבודה מהירה",
	walkthrough_step_1_line_3: "היכרות עם המערכת",
	walkthrough_step_1_line_4: "תמיכה",
	walkthrough_step_1_line_5: "aaaaaa",
	walkthrough_step_2_line_1: "התחלת עבודה מהירה",
	walkthrough_step_2_line_2: "עלי",
	walkthrough_step_2_line_3:
		"הגדרות עבודה בסיסיות (פגישות/יומן - חשבוניות - העדפות בסיסיות)",
	walkthrough_step_2_line_4:
		"נתונים ראשוניים(מטופלים/יומן) [אפשרות קליטה בטבלה?, קבוצות?)",
	walkthrough_step_2_line_5: "aaaaaa",
	walkthrough_step_3_line_1: "מ333333",
	walkthrough_step_3_line_2: "333333",
	walkthrough_step_3_line_3: "3333",
	walkthrough_step_3_line_4: "3333333333",
	walkthrough_step_3_line_5: "3333",
	walkthrough_step_4_line_1: "444444",
	walkthrough_step_4_line_2: "444444",
	walkthrough_step_4_line_3: "444444",
	walkthrough_step_4_line_4: "444444",
	walkthrough_step_4_line_5: "444444",
	walkthrough_step_5_line_1: "support",
	walkthrough_step_5_line_2: "55555",
	walkthrough_step_5_line_3: "55555",
	walkthrough_step_5_line_4: "55555",
	walkthrough_step_5_line_5: "aaaaaa",
	moves_to_next_day:
		"The meeting is too long- ending on the next day, please shorten",
	for_bank_transfer_payment: "To pay via bank transfer",
	create_scheduler: "Schedule",
  sms_real_debt: 'Text debt and not bill',
  wide_logo: 'Enable wide logo',
  calc_age_by_months: 'calculate age by months',
  fixed_intervals: 'Fixed intervals',
  irregular_intervals: 'Irregular intervals',
  interval: 'Interval in days',
  repetitions: 'Repetitions',
  once_more: 'Once more',
  properties: 'Properties',
  id_number_on_patient_banner: 'Id number and age on patient banner',
  extra_2_months_back:'extra 2 more months',
  error_changes_will_not_be_saved:'There is an error in the form. The changes will be lost',
  do_you_want_to_save_changes:'Do you want to save changes?',
  instruction_text1:'We will be happy to guide you through your first steps in the system. How would you like us to coordinate the training?',
  instruction_text2:'Phone number',
  instruction_text3:'Get back to me',
  not_all_questions_answered:'Not all questions answered',
  fill_questions_numbers:'Please select question numbers',
}
